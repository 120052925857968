<template>
  <div class="container-fluid py-4">
    <div class="d-flex justify-content-center align-items-center mb-3">
      <img
          class="w-25 me-3 mb-0 backdrop"
          style="width: 10% !important;"
          src="@/assets/img/illustrations/boy-dynamic-gradient.png"
          alt="logo"
      />
      <h2>Manage Experts</h2>
      <DocumentationLink
          popoverTitle="Manage Experts"
          class="z-index-5"
          description="Learn more about the Managing Experts"
          docLink="https://docs.b-bot.space/essentials/edit-expert"
      />
      <button type="button" class="btn close-btn" @click="closeModal">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <button class="btn btn-primary w-100" @click="openCreateModal">Create new Expert</button>

    </div>
    <div class="row">
      <div v-if="experts.length === 0" class="container p-0 text-center">
        <p>No experts found. you should click on add new expert</p>
      </div>
      <div v-for="expert in experts" :key="expert.id" class="col-md-3 col-lg-2 mb-3">
        <div class="card shadow-sm">
          <span class="badge badge-success" v-if="user.user_metadata.mexpert_id === expert.id">B-Bot ME</span>
          <div class="card-body text-center">
            <img :src="expert.attributes?.profile_picture?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'" class="rounded-circle mb-3" :alt="expert.attributes?.name" style="width: 100px; height: 100px;" />
            <h5 class="card-title">{{ expert.attributes.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>

          </div>
          <div class="d-flex justify-content-around">
            <button class="btn btn-dark m-0 w-50 delete-border" @click="chooseExpert(expert)">
              <i class="material-icons-round opacity-10 fs-6 menu-icon">touch_app</i>
            </button>
            <button class="btn btn-primary m-0 w-50 edit-border" @click="openEditModal(expert)">
              <i class="material-icons-round opacity-10 fs-6 menu-icon">edit</i>
            </button>
            <button class="btn btn-danger m-0 w-50 choose-border" @click="deleteExpert(expert)">
              <i class="material-icons-round opacity-10 fs-6 menu-icon">delete</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ExpertCreateModal ref="expertCreateModal" :initialExpert="selectedExpert" />
    <ExpertModal ref="expertModal" :isEditMode="isEditMode" :initialExpert="selectedExpert" />
  </div>
</template>


<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import ExpertModal from './components/ExpertModal.vue';
import ExpertCreateModal from "@/views/components/ExpertCreateModal.vue";
import DocumentationLink from "./components/DocumentationLink.vue";
import ExpertService from "@/classes/services/ExpertService";

export default {
  name: 'ExpertsTable',
  components: {
    DocumentationLink,
    ExpertCreateModal,
    ExpertModal
  },
  computed: {
    ...mapState(['color', 'user']),
    ...mapState('experts', ['experts']),
  },
  data() {
    return {
      isEditMode: false,
        selectedExpert: {},
    };
  },
  mounted() {
    console.log('Component mounted. Manage Experts', this.user);
    this.$store.state.showNavbar = true; // Hide the navbar when the component is mounted
    this.showEveryDisplay();
  },
  methods: {
    ...mapMutations(["showEveryDisplay"]),
    ...mapActions("experts", ["setSelectedExpert"]),
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    openCreateModal() {
      this.isEditMode = false;
      this.selectedExpert = null; // Reset or set default values for new expert
      this.$refs.expertCreateModal.openModal();
    },
    openEditModal(expert) {
      this.isEditMode = true;
      this.selectedExpert = { ...expert }; // Load the selected expert's details
      this.setSelectedExpert(expert)
      this.$refs.expertModal.openModal();
    },
    async chooseExpert(expert){
      await this.setSelectedExpert(expert)
      console.log("toggleModal")
      this.$emit('toggle-modal');
    },
    async closeModal(){
      this.$emit('toggle-modal');
    },
    async deleteExpert(expert) {
      if (!confirm("Are you sure you want to delete this Expert?")) {
        return;
      }
      await ExpertService.deleteExpert(expert)
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
  },
};
</script>

<style scoped>

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
}

.card-header:first-child {
  border-radius: 1rem;
}
.delete-border{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.edit-border{
  border-radius: 0;
}
.choose-border{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-body {
  flex-grow: 1;
}

.d-flex {
  display: flex;
  align-items: stretch; /* Ensures that all items stretch to the same height */
}

.card img {
  height: 150px; /* Ensures images don't stretch the card */
  object-fit: contain;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
</style>