<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <!-- MODAL HEADER -->
    <template #header>
      <h3>Settings</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <!-- MODAL BODY -->
    <template #default>
      <!-- Header section with color gradient -->
      <div
          :class="'bg-gradient-' + color + ' shadow-' + color"
          class="card-header d-flex justify-content-between card-header-top"
      >
        <h5 class="modal-title text-white">Add File</h5>
      </div>

      <!-- Bootstrap Alert for feedback -->
      <div
          v-if="alertMessage"
          :class="['mt-2 alert', 'alert-' + alertType, 'alert-dismissible', 'fade', 'show']"
          role="alert"
      >
        {{ alertMessage }}
        <button type="button" class="btn-close" aria-label="Close" @click="alertMessage = ''"></button>
      </div>

      <!-- Modern Drag & Drop Upload Card -->
      <div
          class="card dashed-border p-4 d-flex align-items-center justify-content-center text-center my-3"
          @click="() => $refs.fileInput.click()"
          @dragover.prevent
          @drop.prevent="handleDrop"
      >
        <i class="material-icons-round fs-1 mb-2 text-primary">cloud_upload</i>
        <p class="mb-0 dropzone-text">
          Drag & drop or <span class="text-primary">browse</span> to upload file
        </p>
        <input
            ref="fileInput"
            type="file"
            class="d-none"
            id="file-upload"
            @change="handleFileUpload"
        />
      </div>

      <!-- Action Buttons -->
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
      <button @click="closeModal" class="btn btn-success w-100">
        <i class="material-icons-round opacity-10 fs-5">save</i>
      </button>
    </template>

    <!-- MODAL FOOTER -->
    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
      isLoading: false,
      alertMessage: '',
      alertType: '',
    };
  },
  computed: {
    ...mapState(['chatSettings', 'user', 'color']),
    ...mapState('experts', ['selectedExpert']),
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapMutations(['setChatDocumentUrls']),

    closeModal() {
      this.isModalOpen = false;
    },

    // Optional autoClose parameter (default is false)
    showAlert(message, type, autoClose = false) {
      this.alertMessage = message;
      this.alertType = type;
      setTimeout(() => {
        this.alertMessage = '';
        this.alertType = '';
        if (autoClose) {
          this.closeModal();
        }
      }, 5000);
    },

    // Handle single-file upload
    async handleFileUpload(event) {
      this.isLoading = true;
      const file = event.target.files?.[0];

      if (!file) {
        this.isLoading = false;
        this.showAlert('No file selected.', 'danger');
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64File = e.target.result.split(',')[1]; // Get base64 part

        try {
          // Upload the file to your backend and receive the pre-signed URL
          const response = await fetch('/.netlify/functions/uploadFile', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              file: base64File,
              fileName: file.name,
              userId: this.user.user_id,
            }),
          });

          const data = await response.json();
          if (response.ok) {
            // On success, show alert and auto-close the modal after 5 seconds
            this.showAlert('File uploaded successfully! Note: This is a temporary upload.', 'success', true);
            this.downloadUrl = data.downloadUrl;
            this.setChatDocumentUrls([this.downloadUrl]);
          } else {
            this.showAlert(`Upload failed: ${data.message}`, 'danger');
          }
        } catch (error) {
          this.showAlert('Error uploading file', 'danger');
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      };

      reader.onerror = () => {
        this.isLoading = false;
        this.showAlert('Error reading file', 'danger');
      };

      reader.readAsDataURL(file);
    },

    // Optional: Real drag-and-drop
    handleDrop(event) {
      event.preventDefault();
      const droppedFiles = event.dataTransfer.files;
      if (!droppedFiles || !droppedFiles.length) return;

      // Reuse the same handleFileUpload logic
      this.handleFileUpload({ target: { files: droppedFiles } });
    },
  },
};
</script>

<style scoped>
/* Positioning for the 'Close' button in the corner */
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
}

/* Modal container adjustments */
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.dark .confirm-modal-content {
  background: #000;
}

/* Card header rounding */
.card-header {
  border-radius: .75rem .75rem .75rem .75rem !important;
}

/* Dashed border styling for the drag-and-drop area */
.dashed-border {
  border: 2px dashed #ced4da;
  border-radius: 1rem; /* matches .rounded in Bootstrap 5 */
  cursor: pointer;
}

.dashed-border:hover {
  border-color: #adb5bd;
}

.dropzone-text {
  color: #6c757d;
  font-weight: 500;
}

/* Hide the real file input */
input#file-upload[type="file"] {
  display: none;
}
</style>
