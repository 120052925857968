import { createStore } from "vuex";
import { authService } from '@/auth/authService';
const { VUE_APP_MAIN_API_URL } = process.env;
import createPersistedState from 'vuex-persistedstate';
import ChatImage from "@/assets/img/illustrations/chat-text-dynamic-gradient.png";
import {firebaseService} from "../../services/firebaseService";
import router from '../router/index';
import experts from './experts';
import training from "@/store/training";
import {DatacenterService} from "@/classes/services/DatacenterService";
export const datacenterService = new DatacenterService();

export default createStore({
  modules: {
    experts,
    training,
  },
  state: {
    incomingMessage: "",
    incomingToolEvents: [],
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    color: "dark",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: false,
    showFooter: false,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "px-4 mx-0 w-100 z-index-2",
    isAuthenticated: false,
    accessToken: "",
    code: "",
    user: null,
    chatMessages: [],
    isLoading: false,
    isBackgroundLoading: false,
    isErrorInRequest: false,
    unsavedChanges: false,
    selectedConversationId: null,
    isSidebarOpen: false,
    author: null,
    abo: {},
    chatSettings: {
      temperature: 0.1,
      top_p: 0.1,
      instructions: "Always use mermaid for creating graphics and diagrams",
      private_session: false,
      streaming: true,
    },
    toolActivation: {
      tavily_search: true,
      wolfram_alpha: true,
      team_members: true,
      document_retriever: true,
    },
    notifications: [],
    selectedTemplateText: "",
    chatDocumentUrls: [],
    apps: [
      {
        id: 1,
        identifier: 'upstash_redis_public',
        category: "database",
        name: 'Upstash Redis Public',
        description: 'The Public Vector Database for B-Bot',
        logo: 'https://upstash.com/logo/upstash-white-bg.svg',
        connected: false,
        database: true,
        database_type: 'upstash',
        connectable: false,
        href: 'https://upstash.com',
        settings: {
          private: true,
          url: 'https://api.upstash.com',
          apiKey: '',
        },
        descriptions: {
          private: '',
          url: 'API URL for Upstash',
          apiKey: 'API Key for Upstash',
        },
        validations: {
        },
      },
      {
        id: 2,
        identifier: 'upstash_redis_private',
        category: "database",
        name: 'Own Upstash Redis',
        description: 'Choose your own Upstash Redis Database',
        logo: 'https://upstash.com/logo/upstash-white-bg.svg',
        connected: false,
        connectable: true,
        database: true,
        database_type: 'upstash',
        href: 'https://upstash.com',
        settings: {
          private: true,
          redis_rest_url: 'https://something-44230.upstash.io',
          redis_rest_token: 'GAAIncDE2ODFiMDA0Mjc0NmU0NmIzOTU3MDcyZmNl',
          vector_url: 'https://api.upstash.com',
          vector_token: 'GAAIncDE'
        },
        descriptions: {
          private: '',
          redis_rest_url: 'Upstash Redis Rest URL, can be found in the dashboard',
          redis_rest_token: 'Upstash Redis Rest TOKEN, can be found in the dashboard',
          vector_url: 'Upstash Vector URL, can be found in the dashboard',
          vector_token: 'Upstash Vector TOKEN, can be found in the dashboard',
        },
        validations: {
          redis_rest_url: {
            required: true,
            type: 'string',
            pattern: 'https://.*',
            custom: (value) => {
              if (!value.startsWith('https')) {
                return 'URL must start with https';
              }
              return true;
            }
          },
          vector_url: {
            required: true,
            type: 'string',
            pattern: 'https://.*',
            custom: (value) => {
              if (!value.startsWith('https')) {
                return 'URL must start with https';
              }
              return true;
            }
          },
          vector_token: {
            required: true,
            type: 'string',
            minLength: 32,
            maxLength: 64,
            custom: (value) => {
              if (!/^[a-zA-Z0-9]+$/.test(value)) {
                return 'API Key must be alphanumeric';
              }
              return true;
            }
          },
          redis_rest_token: {
            required: true,
            type: 'string',
            minLength: 32,
            maxLength: 64,
            custom: (value) => {
              if (!/^[a-zA-Z0-9]+$/.test(value)) {
                return 'API Key must be alphanumeric';
              }
              return true;
            }
          },
        },
      },
      {
        id: 3,
        identifier: 'redis_private',
        category: "database",
        name: 'Own Redis',
        description: 'Choose your own Redis Database',
        logo: 'https://upstash.com/logo/upstash-white-bg.svg',
        connected: false,
        connectable: true,
        database: true,
        database_type: 'redis',
        href: 'https://upstash.io',
        settings: {
          private: true,
          redis_rest_url: 'redis://default:AazGU0NmIzOTU3MDcyZmNlOWVhZTdkZnAxNDQyMzA@example-koala-44230.redis.io:6379',
        },
        descriptions: {
          private: '',
          redis_rest_url: 'Redis URL of your own private Redis Database',
        },
        validations: {
          redis_rest_url: {
            required: true,
            type: 'string',
            pattern: 'redis://.*',
            custom: (value) => {
              if (!value.startsWith('redis://')) {
                return 'URL must start with redis://';
              }
              return true;
            }
          },
        },
      },
      {
        id: 4,
        identifier: 'notion_connector',
        category: "Connector",
        name: 'Notion Connector',
        description: 'Synchronize your database with B-Bot.',
        logo: 'https://cdn.freelogovectors.net/wp-content/uploads/2023/09/notionlogo-freelogovectors.net_.png',
        connected: false,
        connectable: true,
        href: 'https://upstash.com',
        settings: {
          private: true,
          db: '',
          secret: '',
        },
        descriptions: {
          private: '',
          db: 'Database ID of Notion Page of Folder',
          secret: 'Secret key for the Notion API (you must create our own App in the Notion App)',
        },
      },
      {
        id: 5,
        identifier: 'tavily_search',
        category: "search",
        name: 'Tavily Search',
        description: 'GPT Researcher that researches the Internet for you',
        logo: 'https://docs.tavily.com/img/tavily.png',
        connected: false,
        connectable: false,
        href: 'https://upstash.com',
        settings: {
          private: true,
          url: 'https://api.upstash.com',
          apiKey: '',
        },
        descriptions: {
          private: '',
          url: 'Url for Tavily Search API',
          apiKey: 'API Key for Tavily Search API',
        },
        validations: {
          url: {
            required: true,
            type: 'string',
            pattern: 'https://.*',
            custom: (value) => {
              if (!value.startsWith('https')) {
                return 'URL must start with https';
              }
              return true;
            }
          }
        },
      },
      {
        id: 6,
        identifier: 'wolfram_alpha',
        category: "calculate",
        name: 'Wolfram Alpha',
        description: 'Calculates and answers scientific questions.',
        logo: 'https://w7.pngwing.com/pngs/767/137/png-transparent-wolfram-alpha-logo-search-engines.png',
        connected: false,
        connectable: false,
        href: 'https://upstash.com',
      },
      {
        id: 7,
        identifier: 'openapi_hubspot_companies',
        category: "crm",
        name: 'Hubspot Connection Companies',
        description: 'Creates Connection to Hubspot trough API KEY.',
        logo: 'https://1000logos.net/wp-content/uploads/2022/12/HubSpot-Logo.png',
        connected: false,
        connectable: true,
        href: 'https://hubspot.com',
        settings: {
          private: true,
          tool_type: 'openapi',
          name: 'Hubspot Connection Companies',
          description_addition: 'You can ask this tool about Companies in the CRM',
          openapi_yml_url: 'https://api.hubspot.com/api-catalog-public/v1/apis/crm/v3/objects/companies',
          allow_dangerous_operations: false,
          api_key: 'pat-eu1-711b6711-268e-42b4-91c6-8f878reg',
          api_key_type: 'bearer_no_access_fetching_yml'
        },
        descriptions: {
          private: '',
          api_key: 'API Key for Hubspot API',
          allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
        },
        validations: {
          api_key: {
            required: true,
          }
        },
      },
      {
        id: 8,
        identifier: 'openapi_hubspot_contacts',
        category: "crm",
        name: 'Hubspot Connection Contacts',
        description: 'Creates Connection to Hubspot trough API KEY.',
        logo: 'https://1000logos.net/wp-content/uploads/2022/12/HubSpot-Logo.png',
        connected: false,
        connectable: true,
        href: 'https://hubspot.com',
        settings: {
          private: true,
          tool_type: 'openapi',
          name: 'Hubspot Connection Contacts',
          description_addition: 'You can ask this tool about Contacts in the CRM',
          openapi_yml_url: 'https://api.hubspot.com/api-catalog-public/v1/apis/crm/v3/objects/contacts',
          allow_dangerous_operations: false,
          api_key: 'pat-eu1-711b6711-268e-42b4-91c6-8f878reg',
          api_key_type: 'bearer_no_access_fetching_yml'
        },
        descriptions: {
          private: '',
          api_key: 'API Key for Hubspot API',
          allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
        },
        validations: {
          api_key: {
            required: true,
          }
        },
      },
      {
        id: 9,
        identifier: 'openapi_hubspot_products',
        category: "crm",
        name: 'Hubspot Connection Products',
        description: 'Creates Connection to Hubspot trough API KEY.',
        logo: 'https://1000logos.net/wp-content/uploads/2022/12/HubSpot-Logo.png',
        connected: false,
        connectable: true,
        href: 'https://hubspot.com',
        settings: {
          private: true,
          tool_type: 'openapi',
          name: 'Hubspot Connection Products',
          description_addition: 'You can ask this tool about Products in the CRM',
          openapi_yml_url: 'https://api.hubspot.com/api-catalog-public/v1/apis/crm/v3/objects/products',
          allow_dangerous_operations: false,
          api_key: 'pat-eu1-711b6711-268e-42b4-91c6-8f878reg',
          api_key_type: 'bearer_no_access_fetching_yml'
        },
        descriptions: {
          private: '',
          api_key: 'API Key for Hubspot API',
          allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
        },
        validations: {
          api_key: {
            required: true,
          }
        },
      },
      {
        id: 10,
        identifier: 'spoonacular',
        category: "food",
        name: 'Spoonacular',
        description: 'The spoonacular Nutrition, Recipe, and Food API allows you to access over thousands of recipes, thousands of ingredients, 800,000 food products, over 100,000 menu items, and restaurants.',
        logo: 'https://spoonacular.com/application/frontend/images/logo-simple-framed-green-gradient.svg',
        connected: false,
        connectable: true,
        href: 'https://hubspot.com',
        settings: {
          private: true,
          tool_type: 'openapi',
          name: 'Spoonacular',
          description_addition: 'The spoonacular Nutrition, Recipe, and Food API allows you to access over thousands of recipes, thousands of ingredients, 800,000 food products, over 100,000 menu items, and restaurants.',
          openapi_yml_url: 'https://spoonacular.com/application/frontend/images/logo-simple-framed-green-gradient.svg',
          allow_dangerous_operations: false,
          api_key: 'a100d942744541f7a...',
          api_key_type: 'x-api-key'
        },
        descriptions: {
          private: '',
          api_key: 'API Key for Spoonacular',
          allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
        },
        validations: {
          api_key: {
            required: true,
          }
        },
      },
      {
        id: 11,
        identifier: 'zefix',
        category: "search",
        name: 'Zefix',
        description: '"Zentraler Firmen Index" of Switzerland',
        logo: 'https://www.zefix.ch/assets/images/logo.svg',
        connected: false,
        connectable: true,
        href: 'https://zefix.ch',
        settings: {
          private: true,
          tool_type: 'openapi',
          name: 'Spoonacular',
          description_addition: 'If you need information about companies in Switzerland',
          openapi_yml_url: 'https://www.zefix.admin.ch/ZefixPublicREST/v3/api-docs',
          allow_dangerous_operations: false,
          api_key: 'a100d942744541f7a2.....',
          api_key_type: 'basic'
        },
        descriptions: {
          private: '',
          api_key: 'API Key for Zefix, encode your email:password in Base64 an pass it in here',
          allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
        },
        validations: {
          api_key: {
            required: true,
          }
        },
      },
      {
        id: 12,
        identifier: 'shopware_storefront',
        category: "ecommerce",
        name: 'Shopware Storefront',
        description: 'The Storefront App for retrieving Product Data in Shopware',
        logo: 'https://www.eevolution.de/wp-content/uploads/2023/08/shopware_logo_blue-1.webp',
        connected: false,
        connectable: true,
        href: 'https://showpare.com',
        settings: {
          private: true,
          tool_type: 'openapi',
          name: 'Shopware',
          description_addition: 'If you need information about Products in a Shopware Store.',
          openapi_yml_url: '/store-api/_info/openapi3.json',
          domain: 'https://your-website.ch',
          api_key: 'SWSCVEXTDMFKRE5E.....',
          api_key_type: 'sw-access-key',
          allow_dangerous_operations: false
        },
        descriptions: {
          private: '',
          api_key: 'API Key for Shopware',
          allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
        },
        validations: {
          api_key: {
            required: true,
          }
        },
      },
      {
        id: 13,
        identifier: 'openai_assistant',
        category: "assistants",
        name: 'OpenAI Assistant',
        description: 'An Assistant for you B-Bot to help you with your tasks',
        logo: 'https://media.licdn.com/dms/image/D5612AQEzwj7EvpcXHQ/article-cover_image-shrink_720_1280/0/1700332696859?e=2147483647&v=beta&t=O_m9znUFQLgn06PXf7Ck6UgXNcztGq5-CpDwHTdgzLU',
        connected: false,
        connectable: true,
        href: 'https://platform.openai.com/docs/assistants/overview',
        settings: {
          private: true,
          tool_type: 'openai_asst',
          name: 'Name of the Assistant',
          description: 'Description of the Assistant',
          assistant_id: 'asst_1234567890',
          apiKey: 'sk-1234567890',
        },
        descriptions: {
          private: '',
          tool_type: 'API URL for Upstash',
          name: 'Name of the Assistant tool, e.g. Law Assistant (This is the Tool name B-Bot sees)',
          description: 'Description of the Assistant tool, e.g. Assists in Law questions knows everything about law. (This is the Tool description B-Bot sees)',
          assistant_id: 'The Assistant ID for OpenAI Assistant',
          apiKey: 'API Key of the OpenAI',
        },
      },
    ],

    currentChat: null,
    userChats: [],
    chatCurrentStep: 'selectExpert',
  },
  mutations: {
    setChatCurrentStep(state, step){
      state.chatCurrentStep = step;
    },

    addNotification(state, notification) {
      state.notifications.push(notification);
    },
    updateNotification(state, updatedNotification) {
      const index = state.notifications.findIndex(
          (notification) => notification.id === updatedNotification.id
      );
      if (index !== -1) {
        state.notifications.splice(index, 1, updatedNotification);
      }
    },
    removeNotification(state, notificationId) {
      state.notifications = state.notifications.filter(
          (notification) => notification.id !== notificationId
      );
    },
    clearNotifications(state) {
      state.notifications = [];
    },
    setIncomingMessage(state, message) {
      state.incomingMessage = message;
    },

    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    hideEveryDisplay(state) {
      state.showNavbar = false;
      state.showSidenav = false;
      state.showFooter = false;
    },
    showEveryDisplay(state) {
      state.showNavbar = true;
      state.showSidenav = true;
      state.showFooter = true;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    hideConfig(state) {
      state.hideConfigButton = true;
    },
    color(state, payload) {
      state.color = payload;
    },
    setAuthStatus(state, status) {
      state.isAuthenticated = status;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken; // Assuming you have an accessToken state
    },
    setCode(state, code) {
      state.code = code; // Assuming you have an accessToken state
    },
    setUser(state, user) {
      state.user = user; // Assuming you have an accessToken state
    },
    setAbo(state, abo) {
      state.abo = abo; // Assuming you have an accessToken state
    },
    // REFACTORING NOTE
    updateApps(state, newTools) {
      state.apps = [...state.apps, ...newTools];
    },
    pushIncomingToolEvent(state, event){
      console.log(event);
      state.incomingToolEvents.push(event);

    },
    wipeIncomingToolEvent(state) {
      state.incomingToolEvents = [];
    },
    pushMessage(state, message) {
      console.log(state.chatMessages)
      state.chatMessages = Array.isArray(state.chatMessages) ? state.chatMessages : Object.values(state.chatMessages);

      state.chatMessages.push(message);
    },
    wipeChat(state) {
      state.chatMessages = [];
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsBackgroundLoading(state, isLoading) {
      state.isBackgroundLoading = isLoading;
    },
    setIsErrorInRequest(state, isError) {
      state.isErrorInRequest = isError;
    },
    setUnsavedChanges(state, hasChanges) {
      state.unsavedChanges = hasChanges;
    },
    // REFACTORING NOTE
    setSelectedTemplateText(state, text) {
      state.selectedTemplateText = text;
    },
    setSelectedConversation(state, conversation) {
      state.selectedConversationId = conversation.chatId;
      state.currentChat = conversation.chatId;
      state.chatMessages = conversation.messages;
    },

    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    updateUserMetadataFromApps(state, { key, value }) {
      state.user.user_metadata.apps = {
        ...state.user.user_metadata.apps,
        [key]: value
      };
    },
    deleteUserMetadataFromApps(state, key) {
      delete state.user.user_metadata.apps[key];
    },
    async updateUserMetadata(state, { key, value }) {
      // Ensure this does not add unwanted top-level properties
      state.user.user_metadata = {
        ...state.user.user_metadata,
        [key]: value
      };
      console.log("after updateUserMetadata", state.user.user_metadata);
    },
    setAuthor(state, author) {
      state.author = author;
    },

    setStatistics(state, statistics) {
        state.statistics = statistics;
    },
    addStatistics(state, statistic) {
        state.statistics.push(statistic);
    },
    updateStatistics(state, { statisticId, updatedData }) {
        const index = state.statistics.findIndex(statistic => statistic.id === statisticId);
        if (index !== -1) {
            state.statistics[index] = {
                ...state.statistics[index],
                ...updatedData
            };
        }
    },
    updateChatSettings(state, { setting, value }) {
      // Safer check for property existence
      if (Object.prototype.hasOwnProperty.call(state.chatSettings, setting)) {
        state.chatSettings[setting] = value;
      }
    },
    toggleToolActivation(state, { tool, value }) {
      // Use the same type of check for safe property modification
      if (Object.prototype.hasOwnProperty.call(state.toolActivation, tool)) {
        state.toolActivation[tool] = value;
      }
    },

    setCurrentChat(state, chatId) {
      state.currentChat = chatId;
    },
    setChatMessages(state, messages) {
      state.chatMessages = messages;
    },
    setUserChats(state, chats) {
      state.userChats = chats;
    },
    setChatDocumentUrls(state,urls){
      state.chatDocumentUrls = urls;
    },
    wipeChatDocumentUrls(state){
      state.chatDocumentUrls = [];
    }
  },
  actions: {

    addNotification({ commit }, notification) {
      commit('addNotification', notification);
    },
    removeNotification({ commit }, notificationId) {
      commit('removeNotification', notificationId);
    },
    clearNotifications({ commit }) {
      commit('clearNotifications');
    },
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    setIsLoading({ commit }, payload){
      commit("setIsLoading", payload);
    },
    setIsBackgroundLoading({ commit }, payload){
      commit("setIsBackgroundLoading", payload);
    },
    setIsErrorInRequest({ commit }, isError) {
      commit('setIsErrorInRequest', isError);
    },
    toggleSidebar({ commit }) {
      commit('toggleSidebar');
    },
    selectAbility({ commit }, ability) {
      commit('setSelectedAbility', ability);
    },



    async checkAuthStatus({ commit, state }) {
      // Example of a condition to avoid unnecessary checks
      // This could be more sophisticated based on your routing and authentication logic
      if (!state.isAuthenticated && !localStorage.getItem('isAuthenticated')) {
        try {
          const isAuthenticated = await authService.isAuthenticated();

          // Cache the authentication status to avoid unnecessary checks
          localStorage.setItem('isAuthenticated', isAuthenticated);
          commit('setAuthStatus', isAuthenticated);
        } catch (error) {
          commit('setAuthStatus', false); // Set isAuthenticated to false in case of error
          // Clear any cached authentication status
          localStorage.removeItem('isAuthenticated');
        }
      }
    },
    async updateUserMetadata({ commit, state }, { metadataKey, newValue }) {
      const accessToken = state.user.accessToken; // Assuming accessToken is stored in the user object
      const updateData = {
        user_metadata: {
          ...state.user.user_metadata,
          [metadataKey]: newValue
        }
      };

      try {
        const response = await fetch('/.netlify/functions/updateUserInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(updateData)
        });

        if (response.ok) {
          console.log('Updated user metadata in store:', updateData);
          commit('updateUserMetadata', { key: metadataKey, value: newValue });
        } else {
          console.error('Failed to update user metadata:', await response.text());
        }
      } catch (error) {
        console.error('Error updating user metadata:', error);
      }
    },
    async updateUserMetadataApp({ commit, state }, { key, value }) {
      const accessToken = state.user.accessToken;


      // Prepare the update data including the specific app settings
      const updateData = {
        user_metadata: {
          ...state.user.user_metadata,
          apps: {
            ...state.user.user_metadata.apps,
            [key]: {
              ...value // Update with new settings, no need to preserve existing metadata here
            }
          }
        }
      };
      try {
        const response = await fetch('/.netlify/functions/updateUserInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(updateData)
        });

        if (response.ok) {
          // Update Vuex store with the new metadata
          commit('updateUserMetadataFromApps', { key, value });
        } else {
          console.error('Failed to update user metadata:', await response.text());
        }
      } catch (error) {
        console.error('Error updating user metadata:', error);
      }
    },
    async deleteUserMetadataApp({ commit, state }, key) {
      const accessToken = state.user.accessToken;

      // Filter out the key from the apps object
      const updatedApps = Object.keys(state.user.user_metadata.apps)
          .filter(appKey => appKey !== key)
          .reduce((obj, appKey) => {
            obj[appKey] = state.user.user_metadata.apps[appKey];
            return obj;
          }, {});

      const updateData = {
        user_metadata: {
          ...state.user.user_metadata,
          apps: updatedApps
        }
      };

      try {
        const response = await fetch('/.netlify/functions/updateUserInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(updateData)
        });

        if (response.ok) {
          // Update Vuex store with the new metadata
          commit('deleteUserMetadataFromApps', key);
        } else {
          console.error('Failed to delete user metadata:', await response.text());
        }
      } catch (error) {
        console.error('Error deleting user metadata:', error);
      }
    },
    async fetchAuthor({ commit }) {
      try {
        const fetchResponse = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "authors?populate=*", // Adjust this endpoint as needed
            action: "GET"
          }),
        });

        if (!fetchResponse.ok) {
          throw new Error(`Error: ${fetchResponse.statusText}`);
        }

        const data = await fetchResponse.json();
        const authors = data.data;

        // Store only the first author, if exists
        if (authors.length > 0) {
          commit('setAuthor', authors[0]);
        }

      } catch (error) {
        console.error('Fetching the author failed:', error);
      }
    },
    async editAuthorOfUser({ dispatch }, { authorId, authorData }) {
      try {
        const fetchResponse = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'PUT', // Assuming PUT is used for updates; adjust as needed
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "authors/" + authorId,
            action: "PUT",
            payload: { data: authorData },
          }),
        });

        if (!fetchResponse.ok) {
          const errorResponse = await fetchResponse.json();
          return { success: false, error: errorResponse };
        }

        const responseData = await fetchResponse.json();
        await dispatch('fetchAuthor'); // Refresh the authors list, or similar action
        return { success: true, data: responseData.data };
      } catch (error) {
        console.error('Error editing author:', error);
        return { success: false, error: { message: error.message } };
      }
    },

    async getUser({ commit, dispatch, state }, force = false) {
      try {
        if (!state.user || force) {
          const user = await authService.getUser();
          console.log("GET USER IN STORE", user);
          const userData = await dispatch("fetchDatecenterUser");
          console.log("GET USER DATA", userData);

          // Extract the metadata correctly
          const userWithMetadata = {
            ...user,
            ...userData,
            user_metadata: user.user_metadata || {} // Make sure this matches the Auth0 response structure
          };

          await commit("setUser", userWithMetadata);
        }
      } catch (error) {
        console.error("Fetching user failed:", error);
      }
    },
    async setAbo({commit}, abo){
      await commit('setAbo', abo);

    },
    async fetchDatecenterUser() {
      return await datacenterService.fetchObjects('users',"/me?populate=mexpert");
    },
    async saveChat({ commit, state }) {
      // Check if there's anything to save
      if (state.chatMessages.length === 0) {
        return;
      }

      if(state.selectedConversationId === null){
        try {
          const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              callname: "conversations",
              action: "POST",
              payload: { // The payload expected by your Netlify function
                data: { // The data wrapper expected by Strapi
                  name: state.chatMessages[0].text.substring(0, 50),
                  messages: state.chatMessages,
                  expert: state.selectedExpert?.id,
                }
              }
            }),
          });

          if (!fetchResponse.ok) {
            throw new Error(`Error saving chat: ${fetchResponse.statusText}`);
          }

          await fetchResponse.json();

          // Clear chat messages in Vuex store and localStorage after saving
          //commit('wipeChat');
          localStorage.setItem('chatMessages', JSON.stringify([]));
          localStorage.setItem('unsavedChanges', 'false');
          commit('setUnsavedChanges', false);

        } catch (error) {
          console.error('Error saving chat:', error);
        }
      } else{
        try {
          const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              callname: "conversations/"+ state.selectedConversationId,
              action: "PUT",
              payload: {
                data: {
                  messages: state.chatMessages,
                }
              }
            }),
          });

          if (!fetchResponse.ok) {
            throw new Error(`Error saving chat: ${fetchResponse.statusText}`);
          }

          await fetchResponse.json();

          // Clear chat messages in Vuex store and localStorage after saving
          //commit('wipeChat');
          localStorage.setItem('chatMessages', JSON.stringify([]));
          localStorage.setItem('unsavedChanges', 'false');
          commit('setUnsavedChanges', false);

        } catch (error) {
          console.error('Error saving chat:', error);
        }
      }

    },
    saveMessage({ commit, state }, message) {
      commit('pushMessage', message);
      // Also save the updated chatMessages to localStorage
      localStorage.setItem('chatMessages', JSON.stringify(state.chatMessages));
      // Mark unsavedChanges as true
      commit('setUnsavedChanges', true);
      localStorage.setItem('unsavedChanges', 'true');
    },
    wipeChat({ commit }) {
      commit('wipeChat');
    },
    updateUnsavedChanges({ commit }, hasChanges) {
      commit('setUnsavedChanges', hasChanges);
    },
    async createNewChat({ commit, state, rootState }, title) {
      commit('setIsLoading', true);
      try {
        const chatId = await firebaseService.createChat(title, state.user.user_id, rootState.experts.selectedExpert.id);
        console.log("SET CURRENT CHAT: ", chatId)
        commit('setCurrentChat', chatId);
        //await dispatch('fetchUserChats');
      } catch (error) {
        console.error('Failed to create chat:', error);
        commit('setIsErrorInRequestt', true);
      } finally {
        //commit('setIsLoading', false);
      }
    },
    async sendMessage({ commit, state }, message) {
      if (state.currentChat) {

        try {

          // Call the firebaseService with the whole message object
          await firebaseService.sendMessage(state.currentChat, message);
        } catch (error) {
          console.error('Failed to send message:', error);
          commit('setIsErrorInRequest', true);
        }
      }
    },
    listenToCurrentChat({ commit, state }) {
      if (state.currentChat) {
        return firebaseService.listenToChat(state.currentChat, (messages) => {
          if (messages) {
            // Merge the existing and new messages without replacing the whole array
            commit('setChatMessages', state.chatMessages);
          }
        });
      }
    },
    async fetchUserChats({ commit, state }) {
      commit('setIsLoading', true);
      try {
        console.log("Fetch USER CHATS", state.user)
        // Fetch the user chats from the service
        const userChats = await firebaseService.getUserChats(state.user.user_id);

        // For each chat, fetch the chat details along with its messages
        const chatDetailsWithMessages = await Promise.all(
            Object.keys(userChats).map(async (chatId) => {
              const chatWithMessages = await firebaseService.getChatWithMessages(chatId);
              return {
                chatId,
                chatDetails: chatWithMessages.chatDetails,
                messages: chatWithMessages.messages
              };
            })
        );

        commit('setIsLoading', false);

        commit('setUserChats', chatDetailsWithMessages);
      } catch (error) {
        console.error('Failed to fetch user chats:', error);
        //commit('setIsErrorInRequest', true);
      } finally {
        //commit('setIsLoading', false);
      }
    },
    async fetchUserChatsWithMessages({ commit, state, dispatch }) {
      //commit('setIsLoading', true);

      try {
        // Fetch the user chats from the service
        if(state.user === null){
          dispatch('getUser');
        }
        const userChatIds = await firebaseService.getUserChats(state.user.user_id);


        // For each chat ID, fetch the chat details along with its messages
        const chatDetailsWithMessages = await Promise.all(
            userChatIds.map(async (chatId) => {
              const chatWithMessages = await firebaseService.getChatWithMessages(chatId);
              return {
                chatId,
                chatDetails: chatWithMessages.chatDetails,
                messages: chatWithMessages.messages
              };
            })
        );


        // Commit the chat details and messages to the state
        commit('setUserChats', chatDetailsWithMessages);
      } catch (error) {
        console.error('Failed to fetch user chats with messages:', error);
        commit('setIsErrorInRequest', true); // Set the error state if needed
      } finally {
        commit('setIsLoading', false);
      }
    },
    async sendInvitation({ commit }, invitationData) {
      try {
        await firebaseService.sendInvitation(invitationData);
      } catch (error) {
        console.error('Failed to send invitation:', error);
        commit('setIsErrorInRequestt', true);
      }
    },
    listenForInvitations({ dispatch }, userId) {

      // Keep track of processed invitations to avoid duplicate notifications
      const processedInvitations = new Set();

      firebaseService.listenToInvitations(userId, (invitations) => {
        if (invitations) {

          Object.keys(invitations).forEach((invitationId) => {
            const invitation = invitations[invitationId];

            // Check if this invitation has already been processed
            if (!processedInvitations.has(invitationId)) {
              // Dispatch a notification for the new invitation
              const notification = {
                id: invitationId,
                chatId: invitation.chatId,
                title: 'New Chat Invitation',
                image: ChatImage, // Optional: add an image if necessary
                message: `You have been invited to a new chat by ${invitation.invitedBy}`,
                createdAt: new Date().toISOString(),
                type: 'invite', // You can categorize notifications by type
              };

              dispatch('addNotification', notification);

              // Mark this invitation as processed
              processedInvitations.add(invitationId);
            }
          });
        }
      });
    },
    async fetchPendingNotifications({ dispatch }, userId) {
      const invitations = await firebaseService.getPendingNotifications(userId);

      if (invitations) {
        dispatch('clearNotifications');

        Object.keys(invitations).forEach((invitationId) => {
          const invitation = invitations[invitationId];
          const chatId = invitation.chatId;

          // Dispatch a notification for the invitation
          const notification = {
            id: chatId, // Keep chatId as the main identifier
            chatId: chatId, // Include chatId explicitly
            invitationId: invitationId, // Include invitationId as well
            title: 'New Chat Invitation',
            message: `You have been invited to a new chat by ${invitation.invitedBy}`,
            createdAt: new Date().toISOString(),
            type: 'invite',
          };

          dispatch('addNotification', notification);
        });
      } else {
        //console.log("No pending notifications.");
      }
    },
    async acceptInvitation({ dispatch, commit, state }, { userId, chatId, invitationId }) {
      try {
        await firebaseService.acceptInvitation(userId, chatId, invitationId);

        // Clear notifications
        dispatch('clearNotifications');

        // Fetch the chat details if needed
        dispatch('fetchChatDetails', { chatId });


        // Set the current chatId and step
        state.selectedConversationId = chatId;
        state.chatCurrentStep = 'chat';

        // Filter the chat by chatId from state.userChats
        const selectedConversation = state.userChats.find(chat => chat.chatId === chatId);

        if (selectedConversation) {
          // Commit the mutation to set the selected conversation using the filtered chat details
          commit('setSelectedConversation', selectedConversation);
        } else {
          console.error('Chat not found in userChats for chatId:', chatId);
        }
      } catch (error) {
        console.error('Error accepting invitation:', error);
      }
      dispatch('fetchPendingNotifications', userId);
      // Redirect the user to the chat page
      await router.push(`/chat`);
    },
    async declineInvitation({ dispatch }, { userId, invitationId }) {
      await firebaseService.declineInvitation(userId, invitationId);
      dispatch('clearNotifications');

      dispatch('fetchPendingNotifications', userId); // Optionally, refresh notifications
    },
    async fetchChatDetails({ dispatch, state }, { chatId }) {
      const chatDetails= await firebaseService.getChatWithMessages(chatId);
      state.chatDetails = chatDetails;
      //dispatch('fetchPendingNotifications'); // Optionally, refresh notifications
      dispatch('fetchUserChatsWithMessages'); // Fetch the chat details if needed
    },
    // future will allow to add users directly when they are in your organization
    //async addUserToChat({ state }, { chatId, userId }) {
    //  await firebaseService.addUserToChat(chatId, userId);
    //},

    async saveStatistics({ commit }, { Statistics }) {
      try {
        // Convert QA pairs to the format expected by your backend
        const payload = {
          month: "01.01.2021",
          statistics_data: Statistics
        };

        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: "statistics",
            action: "POST",
            payload: { data: payload }
          })
        });

        if (!response.ok) {
          throw new Error('Failed to save QA pairs');
        }

        await response.json();
        // Optionally, commit a mutation to update state with new QA pair data
        Statistics.forEach(statistic => commit('addStatistics', statistic));
        commit("fetchStatistics");
      } catch (error) {
        console.error('Error saving QA pairs:', error);
      }
    },
    async updateStatistics({ commit }, { statisticId, Statistics }) {
      try {
        const payload = { statistics_data: Statistics };
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: `statistics/${statisticId}`,
            action: "PUT",
            payload: { data: payload }
          })
        });

        if (!response.ok) {
          throw new Error('Failed to update QA pair');
        }

        await response.json();

        commit('updateStatistics', { statisticId, Statistics }); // Update local state
      } catch (error) {
        console.error('Error updating QA pair:', error);
      }
    },
    async fetchStatistics({ commit }) {
      try {
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: `statistics`,
            action: "GET",
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch QA pairs');
        }

        const responseData = await response.json();
        // set Statistics after fetching
        commit('setStatistics', responseData.data);

      } catch (error) {
        console.error('Error fetching QA pairs:', error);
      }
    },
    async callApiFunction(context, { functionName, payload }) {
      try {
        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/${functionName}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to call API:', error);
        return null;
      }
    },
    async toggleToolActivation({ commit }, payload) {
      commit('toggleToolActivation', payload);
    }
  },
  getters: {
    isSidebarOpen: state => state.isSidebarOpen,
    notifications: (state) => state.notifications,

  },
  plugins: [
    createPersistedState({
      paths: [
        'hideConfigButton',
        'isPinned',
        'showConfig',
        'sidebarType',
        'sidebarType',
        'isRTL',
        'color',
        'isNavFixed',
        'isAbsolute',
        'showNavs',
        'showSidenav',
        'showNavbar',
        'showFooter',
        'showMain',
        'isDarkMode',
        'navbarFixed',
        'absolute',
        'chatSettings',
        'toolActivation',
        'currentChat',
        'experts.experts',
        'experts.selectedExpert',
      ],
    }),
    store => {
      store.subscribe((mutation, state) => {
        if (mutation.type === 'setAuthStatus') {
          localStorage.setItem('isAuthenticated', state.isAuthenticated);
        }
      });

      const isAuthenticated = localStorage.getItem('isAuthenticated');
      if (isAuthenticated !== null) {
        store.commit('setAuthStatus', JSON.parse(isAuthenticated));
      }
    }
  ]
});
