import store from '../store'; // Assuming you have Vuex store setup


class AuthService {
    constructor() {
        this.auth0 = null;
        this.initClient();
    }

    async initClient() {
        //ad init here
    }



    async handleRedirectCallback() {
        // write handle redirect Callback
        //await this.auth0.handleRedirectCallback();
        window.history.replaceState({}, document.title, '/'); // Remove the code from the URL
        store.commit('setAuthStatus', true); // Update the authentication status in the store
    }

    async isAuthenticated() {
        return store.state.isAuthenticated
    }

    async login() {
        window.location.href = '/.netlify/functions/login';
    }

    async logout() {
        window.location.href = '/.netlify/functions/logout';
    }

    async getUser() {
        try {
            const response = await fetch('/.netlify/functions/getUserInfo');

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            console.log("USER IN AUTH SERVICE:", await response);
            store.commit('setAuthStatus', true);

            return await response.json();
        } catch (error) {
            if (window.location.pathname !== '/sign-in') {
                window.location.href = '/sign-in'; // Redirect to sign-in page
            }
            console.error('Fetching user information failed:', error);
        }
    }

    async getTokenSilently() {
        //getTokenSilently
    }
}

export const authService = new AuthService();
