// node-templates.js

export const nodeTemplates = {
    input: {
        inputs: 0,
        outputs: 1,
        data: {
            name: 'Input'
        },
        html: `
      <div class="node-content">
        <div class="node-header">
          <span class="material-icons">input</span>
          Input
        </div>
        <div class="node-body">
          <div class="node-form">
            <input type="text" df-name placeholder="Input Name">
          </div>
        </div>
      </div>
    `
    },
    process: {
        inputs: 1,
        outputs: 1,
        data: {
            name: 'Process'
        },
        html: `
      <div class="node-content">
        <div class="node-header">
          <span class="material-icons">settings</span>
          Process
        </div>
        <div class="node-body">
          <div class="node-form">
            <input type="text" df-name placeholder="Process Name">
            <select df-type>
              <option value="transform">Transform</option>
              <option value="filter">Filter</option>
              <option value="aggregate">Aggregate</option>
            </select>
          </div>
        </div>
      </div>
    `
    },
    condition: {
        inputs: 1,
        outputs: 2,
        data: {
            name: 'Condition'
        },
        html: `
      <div class="node-content">
        <div class="node-header">
          <span class="material-icons">call_split</span>
          Condition
        </div>
        <div class="node-body">
          <div class="node-form">
            <input type="text" df-condition placeholder="Enter condition">
          </div>
        </div>
      </div>
    `
    },
    output: {
        inputs: 1,
        outputs: 0,
        data: {
            name: 'Output'
        },
        html: `
      <div class="node-content">
        <div class="node-header">
          <span class="material-icons">output</span>
          Output
        </div>
        <div class="node-body">
          <div class="node-form">
            <input type="text" df-name placeholder="Output Name">
          </div>
        </div>
      </div>
    `
    }
}