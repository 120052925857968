<template>
  <div>
    <!-- Form Title -->
    <h3>Setup Auth Scheme for {{ authData.name }}</h3>
    <!-- Dynamic Form Fields -->
    <div v-for="(scheme, schemeIndex) in authData.auth_schemes" :key="schemeIndex">
      <h4>Scheme Type: {{ scheme.type }}</h4>
      <div v-if="scheme.type ==='OAUTH2'">
        <div class="alert alert-info mb-4" role="alert">
          <h4 class="alert-heading">How to easy Authenticate</h4>
          <p class="mb-0">
            This app offers oauth authentication, you can just click connect if you not need a specific api key setup
          </p>
        </div>
      </div>
      <div v-else>
        <!-- Customer Required Fields -->
        <MaterialInput
            class="mb-2"
            v-for="field in scheme.customer_required_fields"
            :key="field"
            v-model="formData.customer[field]"
            :label="formatLabel(field)"
            :type="getInputType(field)"
            :error="formErrors.customer[field]"
            @input="validateField(field, 'customer')"
            :id="field"
        />

        <!-- System Required Fields -->
        <MaterialInput
            class="mb-2"
            v-for="field in scheme.system_required_fields"
            :key="field"
            v-model="formData.system[field]"
            :label="formatLabel(field)"
            :type="getInputType(field)"
            :error="formErrors.system[field]"
            @input="validateField(field, 'system')"
            :id="field"
        />
      </div>

    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  components: { MaterialInput },
  props: {
    authData: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    value: {  // Bind v-model to the `value` prop
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        customer: {},
        system: {},
      },
      formErrors: {
        customer: {},
        system: {},
      },
    };
  },
  mounted() {
    // Initialize formData based on authData.auth_schemes
    this.initializeFormData();
  },
  methods: {
    connectButtonClick(){
      console.log("clicked connect button");
      this.$emit("connect");
    },
    // Initialize form data dynamically
    initializeFormData() {
      if (this.authData?.auth_schemes) {
        this.authData.auth_schemes.forEach((scheme) => {
          this.formData.customer = {
            ...this.formData.customer,
            ...scheme.customer_required_fields?.reduce((acc, field) => {
              acc[field] = acc[field] || ""; // Default empty value
              return acc;
            }, {}),
          };

          this.formData.system = {
            ...this.formData.system,
            ...scheme.system_required_fields?.reduce((acc, field) => {
              acc[field] = acc[field] || "";
              return acc;
            }, {}),
          };
        });
      }
    },

    // Field validation logic
    validateField(field, type) {
      this.formErrors[type][field] = !this.formData[type][field]
          ? "This field is required."
          : "";
      this.$emit("form-valid", this.isFormValid());
      console.log("VALIDATE FORM DATA: ", this.formData);
      this.updateFormData(this.formData);  // Update parent with new formData

      this.$emit("update", this.formData);  // Emit updated data to parent

    },

    // Check overall form validity
    isFormValid() {
      return Object.values(this.formErrors.customer).every((err) => !err) &&
          Object.values(this.formErrors.system).every((err) => !err);
    },

    // Infer input type
    getInputType(field) {
      if (field.toLowerCase().includes("key") || field.toLowerCase().includes("secret")) {
        return "password";
      }
      if (field.toLowerCase().includes("url")) {
        return "url";
      }
      return "text";
    },

    // Format label text
    formatLabel(field) {
      return field.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
    },
    updateFormData(newData) {
      this.$emit("update", newData);  // Emit updated data to parent
    },
  },
  watch: {
    authData: {
      handler() {
        this.initializeFormData();
      },
      deep: true,  // Ensure deep watching for nested changes
    },
  },
};
</script>

<style scoped>

.choose-expert {
  padding-left: 1.73em;
}
.active {
  border: 2px solid #66bb6a;
}
</style>
