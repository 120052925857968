<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Memories</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #default>
      <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-start card-header-top">
        <DocumentationLink
            popoverTitle="Chat Settings"
            class="z-index-5"
            description="Learn more about Chat Settings."
            docLink="https://docs.b-bot.space/essentials/chat#the-settings-window"
        />
        <h5 class="modal-title text-white mx-5">Memories</h5>
      </div>
      <div class="col-md-12 mt-3">
        <div v-if="documents.hits.hits || documents.hits.hits.length !== 0" id="accordionExample">
          <div v-for="(doc, index) in documents.hits.hits" :key="index" class="accordion-item">
            <h2 class="accordion-header" :id="'heading' + index">
              <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + index"
                  aria-expanded="true"
                  :aria-controls="'collapse' + index"
              >
                <h5 class="m-0">{{ doc._source.text.slice(0, 20) }}...</h5>
              </button>
            </h2>
            <div
                :id="'collapse' + index"
                class="accordion-collapse collapse"
                :class="{ show: index === 0 }"
                :aria-labelledby="'heading' + index"
                data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <v-md-preview :text="doc._source.text"></v-md-preview>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!documents.hits.hits || documents.hits.hits.length === 0">
          <p>Chat with your Expert and tell him to remember things to add memories</p>
        </div>
      </div>

      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import { mapState } from 'vuex';
import DocumentationLink from './DocumentationLink.vue';
import axios from 'axios';
const { VUE_APP_MAIN_API_URL } = process.env;

export default {
  components: {
    DocumentationLink,
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
      documents: [], // Stores documents fetched from the API
    };
  },
  computed: {
    ...mapState(['user', 'color']),
    ...mapState('experts', ['selectedExpert']),
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    closeModal() {
      this.isModalOpen = false;
    },
    async fetchUserDocuments() {
      console.log(this.user)
      await axios.get(`${VUE_APP_MAIN_API_URL}/api/v0/get_memories?user_ids=${this.user.user_id.replace(/[|-]/g, '') + "_" + this.selectedExpert.id}`)
          .then(response => {
            this.documents = response.data; // Store documents in the data property
          })
          .catch(error => {
            console.error('Error fetching documents:', error);
          });
    },
  },
  async mounted() {
    await this.fetchUserDocuments(); // Fetch the user documents when the component is mounted
  },
};
</script>


<style scoped>

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}

</style>
