<template>
  <DocumentationLink
      class="float-start position-sticky z-index-5"
      description="Learn more about how to use this feature in our documentation."
      docLink="https://docs.b-bot.space"
  />
  <div class="container mt-5">

    <div v-if="experts.length === 0" class="container p-0 text-center">
      <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-info p-0">
        <div class="col-12 col-md-6">
          <div class="alert-icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <img
                class="w-75 me-3 mb-0"
                src="@/assets/img/illustrations/boy-dynamic-gradient.png"
                alt="logo"
          />
        </div>
        <div class="col-12 col-md-6">
          <h1 style="color: white">Welcome to the Knowledge Sector {{user.name}}!</h1>

          <h2 style="color: white">Start with creating you first expert!</h2>
          <p>
            Step into a world where Virtual Experts—digital personas crafted with cutting-edge AI technology—stand ready to engage, guide, and enlighten. These experts simulate real-life interactions, leveraging artificial intelligence and machine learning to provide tailored advice and solutions, enhancing your experience with precision and innovation.
          </p>

        </div>
        <div class="d-flex justify-content-around p-0">
          <a href="https://b-bot-hub.notion.site/What-is-a-Virtual-Expert-Persona-Actor-c11b27e4b8974f9dacfde9ea0abca3ca?pvs=74" class="btn btn-primary w-100 m-0">
            More Information
          </a>
          <button class="btn btn-primary w-100 m-0" @click="redirectToExperts">
            Go to Experts
          </button>
        </div>
      </div>

    </div>

    <div class="row row-cols-md-4 g-3">
      <div v-for="app in filteredApps" :key="app.id" class="col">
        <div v-if="app.tool_type === 'knowledge'"
             class="btn card border border-primary h-100"
             @click="toggleEdit(app)">
          <div class="card-body d-flex flex-column">
            <i class="material-icons-round opacity-10 w-100 menu-icon fs-1">edit</i>
            <h5 class="card-title">Edit {{app.knowledge_name}}</h5>
            <p class="card-text">Edit knowledge Domain</p>
          </div>
        </div>
        <div v-if="app.tool_type === 'memory'"
             class="btn card app-card border border-primary h-100"
             @click="toggleMemoriesModal">
          <div class="card-body d-flex flex-column">
            <i class="material-symbols-outlined opacity-10 w-100 menu-icon fs-1">network_intelligence</i>
            <h5 class="card-title">Memory</h5>
            <p class="card-text">Standard Memory of your expert</p>
          </div>
        </div>
      </div>
      <div v-if="filteredApps.length < 8" class="col">
        <div @click="showNewDomainModal = true"
             class="btn card border border-primary h-100">
          <div class="card-body d-flex flex-column">
            <i class="material-icons-round opacity-10 w-100 menu-icon fs-1">add</i>
            <h5 class="card-title">Add New</h5>
            <p class="card-text">Add a new knowledge Domain</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MemoriesModal v-model="showChatMemoriesModal" />
  <DomainKnowledgeModal v-model="showDomainKnowledgeModal" :knowledgeApp="selectedKnowledgeApp" />
  <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      :class="{ show: showNewDomainModal }"
      style="display: block"
      v-if="showNewDomainModal"
  >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Knowledge Domain</h5>
          <button
              type="button"
              class="btn p-0 m-0"
              aria-label="Close"
              @click="showNewDomainModal = false"
          >
            <i class="material-icons-round opacity-10 fs-5">close</i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createKnowledgeDomain">
            <div class="mb-3">
              <label for="domainName" class="form-label">Name</label>
              <material-input
                  type="text"
                  class="form-control"
                  id="domainName"
                  v-model="newDomain.name"
                  required
              />
            </div>
            <div class="mb-3">
              <label for="domainDescription" class="form-label">Description</label>
              <material-textarea
                  class="form-control"
                  id="domainDescription"
                  v-model="newDomain.description"
                  required
              ></material-textarea>
            </div>
            <button type="submit" class="btn btn-primary w-100">Add</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import welcomeAnimation from '@/assets/img/illustrations/welcomeAnimation.json';
import {mapMutations, mapState} from "vuex";
import DocumentationLink from "./components/DocumentationLink.vue";
import MemoriesModal from "@/views/components/MemoriesModal.vue";
import DomainKnowledgeModal from "@/views/components/DomainKnowledgeModal.vue";
import {mainApiService} from "@/store/training";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";

export default {
  components: {
    MaterialTextarea,
    MaterialInput,
    DomainKnowledgeModal,
    MemoriesModal,
    DocumentationLink,
  },
  props:{
    showMemory: Boolean,
  },
  data() {
    return {
      welcomeAnimation,
      showChatMemoriesModal: false,
      showDomainKnowledgeModal: false,
      showNewDomainModal: false,
      newDomain: {
        name: "",
        description: "",
      },
      selectedKnowledgeApp: {},
    }
  },
  mounted() {
    this.showEveryDisplay();
  },
  computed: {
    ...mapState(['color', 'user']),
    ...mapState('experts', ['experts', 'selectedExpert']),
    filteredApps() {
      const appsObject = this.selectedExpert.attributes.apps;
      return Object.values(appsObject).filter(
          (app) => app.tool_type === 'knowledge' || app.tool_type === 'memory'
      );
    },
  },
  methods: {
    ...mapMutations(["showEveryDisplay"]),
    redirectToExperts() {
      // Redirect to the /experts route
      this.$router.push('/experts');
    },
    toggleMemoriesModal(){
      this.showChatMemoriesModal = !this.showChatMemoriesModal
    },
    toggleEdit(knowledgeApp){
      console.log(knowledgeApp)
      this.selectedKnowledgeApp = knowledgeApp;
      this.showDomainKnowledgeModal = !this.showDomainKnowledgeModal
    },
    async createKnowledgeDomain() {
      const { name, description } = this.newDomain;

      // Validation (only allow a-z, 0-9, and _ in the name)
      if (!/^[a-z0-9_]+$/.test(name)) {
        alert("Name must contain only a-z, 0-9, and _ characters.");
        return;
      }

      // Call the toggleNew method with the input data
      await this.toggleNew(name, description);

      // Reset form and close modal
      this.newDomain = { name: "", description: "" };
      this.showNewDomainModal = false;
    },
    async toggleNew(knowledgeName, knowledgeDescription){
      const modifiedPayload = {
        name: "Indexer_of_" + this.selectedExpert.id + "_knowledge_" + knowledgeName,
        embedding_model: "openai/text-embedding-3-small",
        retriever_provider: "elastic-local",
        url: process.env.VUE_APP_ELASTICSEARCH_URL,
        user_id: this.user.user_id.replace(/[|-]/g, '') + "_" + this.selectedExpert.id + "_knowledge_" + knowledgeName,
        //user_name: "elastic",
        //user_password: "changeme",
        graph_id: "indexer"
      };
      const responseData = await mainApiService.call(
          "create_memory_assistant",
          "POST",
          modifiedPayload,
      );
      console.log(responseData)
      const modifiedPayload2 = {
        name: "Retriever_of_" + this.selectedExpert.id + "_knowledge_" + knowledgeName,
        embedding_model: "openai/text-embedding-3-small",
        retriever_provider: "elastic-local",
        query_model: "openai/gpt-4o-2024-08-06",
        query_system_prompt: "you are a knowledge assistant, you are searching for knowledge, heres a description of the knowledge:" + knowledgeDescription,
        response_model: "openai/gpt-4o-2024-08-06",
        response_system_prompt: "you are a knowledge assistant, you are searching for knowledge, heres a description of the knowledge:" + knowledgeDescription,
        url: process.env.VUE_APP_ELASTICSEARCH_URL,
        user_id: this.user.user_id.replace(/[|-]/g, '') + "_" + this.selectedExpert.id + "_knowledge_" + knowledgeName,
        //user_name: "elastic",
        //user_password: "changeme",
        graph_id: "retrieval_graph"
      };
      const responseData2 = await mainApiService.call(
          "create_memory_assistant",
          "POST",
          modifiedPayload2,
      );
      const updatedApps = {
        ["knowledge_" + knowledgeName]: {
          tool_type: "knowledge",
          knowledge_name: knowledgeName,
          knowledge_description: knowledgeDescription,
          indexer: responseData.assistant_id,
          retriever: responseData2.assistant_id,
        }

      }
      console.log(responseData2)
      await this.selectedExpert.update(
          {
            attributes: {
              ...this.selectedExpert.attributes,
              version: "synapse",
              apps: {
                ...this.selectedExpert.attributes.apps, // Existing apps
                ...updatedApps, // New or updated apps
              }
            }
          }
      )
      await this.selectedExpert.save();

      console.log("then open modal to edit the stores itself to feed data via text or documents")
    }
  },
};
</script>

<style>
/* Styling for your components */
.animation-container {
  display: flex;
  justify-content: center;
}
.update-announcements, .blog-articles {
  text-align: left;
}
.alert-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 1rem;
}
.alert{
  color: white !important;
}
.modal{
  background: rgba(10, 10, 10, 0.2);
}
</style>
