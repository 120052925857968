<template>
  <div class="workflow-builder">
    <div class="controls mb-4">
      <div class="btn-group">
        <button class="btn btn-primary" @click="editor.editor_mode = 'edit'">
          <span class="material-icons">edit</span> Edit Mode
        </button>
        <button class="btn btn-secondary" @click="editor.editor_mode = 'fixed'">
          <span class="material-icons">lock</span> Fixed Mode
        </button>
        <button class="btn btn-info" @click="editor.editor_mode = 'view'">
          <span class="material-icons">visibility</span> View Mode
        </button>
      </div>
      <div class="btn-group ms-2">
        <button class="btn btn-light" @click="editor.zoom_in()">
          <span class="material-icons">zoom_in</span>
        </button>
        <button class="btn btn-light" @click="editor.zoom_out()">
          <span class="material-icons">zoom_out</span>
        </button>
      </div>
      <div class="btn-group ms-2">
        <button class="btn btn-success" @click="exportFlow">
          <span class="material-icons">file_download</span> Export
        </button>
        <button class="btn btn-warning" @click="importFlow">
          <span class="material-icons">file_upload</span> Import
        </button>
        <button class="btn btn-danger" @click="clearFlow">
          <span class="material-icons">delete</span> Clear
        </button>
      </div>
    </div>

    <div class="row">
      <!-- Node Palette -->
      <div class="col-md-3">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">
              <span class="material-icons align-middle me-1">widgets</span>
              Available Nodes
            </h5>
          </div>
          <div class="card-body">
            <div class="node-palette">
              <div
                  v-for="node in nodeTypes"
                  :key="node.type"
                  class="node-item card mb-2"
                  draggable="true"
                  @dragstart="handleDragStart($event, node)"
                  @dragend="handleDragEnd"
              >
                <div class="card-body">
                  <h6>
                    <span class="material-icons align-middle me-1">{{ node.icon }}</span>
                    {{ node.label }}
                  </h6>
                  <small class="text-muted">{{ node.description }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Workflow Canvas -->
      <div class="col-md-9">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="mb-0">
              <span class="material-icons align-middle me-1">account_tree</span>
              Workflow Editor
            </h5>
            <div class="module-controls">
              <select v-model="currentModule" class="form-select" @change="changeModule">
                <option v-for="module in modules" :key="module" :value="module">
                  {{ module }}
                </option>
              </select>
              <button class="btn btn-sm btn-primary ms-2" @click="addNewModule">
                <span class="material-icons">add</span>
                Add Module
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div
                id="drawflow"
                ref="drawflow"
                class="drawflow-canvas"
                @dragover="handleDragOver"
                @drop="handleDrop"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawflow from 'drawflow'
import { h, getCurrentInstance, render } from 'vue'
import { nodeTemplates } from './node-templates/node-templates'
import 'drawflow/dist/drawflow.min.css'

export default {
  name: 'WorkflowBuilder',

  data() {
    return {
      editor: null,
      currentModule: 'Home',
      modules: ['Home'],
      dragPosition: { x: 0, y: 0 },
      nodeTypes: [
        {
          type: 'input',
          label: 'Input Node',
          description: 'Starting point of workflow',
          icon: 'input',
          template: nodeTemplates.input
        },
        {
          type: 'process',
          label: 'Process Node',
          description: 'Process data or perform actions',
          icon: 'settings',
          template: nodeTemplates.process
        },
        {
          type: 'condition',
          label: 'Condition Node',
          description: 'Branch based on conditions',
          icon: 'call_split',
          template: nodeTemplates.condition
        },
        {
          type: 'output',
          label: 'Output Node',
          description: 'End point of workflow',
          icon: 'output',
          template: nodeTemplates.output
        }
      ]
    }
  },

  mounted() {
    this.initializeDrawflow()
    this.setupEventListeners()
    this.setupDragAndDrop()
  },

  methods: {
    initializeDrawflow() {
      const container = this.$refs.drawflow
      const internalInstance = getCurrentInstance()

      // Initialize with Vue 3 support
      this.editor = new Drawflow(
          container,
          { version: 3, h, render },
          internalInstance.appContext.app._context
      )

      // Configure editor settings
      this.editor.reroute = true
      this.editor.reroute_fix_curvature = true
      this.editor.force_first_input = true
      this.editor.zoom_max = 1.5
      this.editor.zoom_min = 0.5
      this.editor.zoom_value = 0.1

      this.editor.start()
    },

    setupEventListeners() {
      // Node events
      this.editor.on('nodeCreated', this.handleNodeCreated)
      this.editor.on('nodeRemoved', this.handleNodeRemoved)
      this.editor.on('nodeSelected', this.handleNodeSelected)
      this.editor.on('nodeMoved', this.handleNodeMoved)

      // Connection events
      this.editor.on('connectionCreated', this.handleConnectionCreated)
      this.editor.on('connectionRemoved', this.handleConnectionRemoved)

      // Module events
      this.editor.on('moduleChanged', this.handleModuleChanged)
    },

    setupDragAndDrop() {
      const container = this.$refs.drawflow

      // Track mouse position during drag
      container.addEventListener('mousemove', (e) => {
        this.dragPosition.x = e.clientX
        this.dragPosition.y = e.clientY
      })
    },

    handleDragStart(event, node) {
      // Set drag data
      event.dataTransfer.setData('node_type', node.type)

      // Set drag effect
      event.dataTransfer.effectAllowed = 'copy'

      // Create and style drag image
      const dragImage = event.target.cloneNode(true)
      dragImage.style.width = '150px'
      dragImage.style.height = '80px'
      dragImage.style.position = 'absolute'
      dragImage.style.top = '-1000px'
      document.body.appendChild(dragImage)
      event.dataTransfer.setDragImage(dragImage, 75, 40)

      // Remove drag image after drag
      setTimeout(() => {
        document.body.removeChild(dragImage)
      }, 0)

      // Add dragging class to canvas
      this.$refs.drawflow.classList.add('dragging')
    },

    handleDragOver(event) {
      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy'
    },

    handleDrop(event) {
      event.preventDefault()

      // Get the node type from drag data
      const nodeType = event.dataTransfer.getData('node_type')
      if (!nodeType) return

      // Get the canvas element and its position
      const container = this.$refs.drawflow
      const rect = container.getBoundingClientRect()

      // Calculate drop position relative to the canvas
      const zoom = this.editor.zoom
      const pos_x = (event.clientX - rect.left) / zoom
      const pos_y = (event.clientY - rect.top) / zoom

      // Add the node at the calculated position
      this.addNode(nodeType, pos_x, pos_y)

      // Remove dragging class from canvas
      container.classList.remove('dragging')
    },

    handleDragEnd() {
      // Clean up any drag-related states
      this.dragPosition = {x: 0, y: 0}
      this.$refs.drawflow.classList.remove('dragging')
    },

    addNode(nodeType, pos_x, pos_y) {
      const nodeConfig = this.nodeTypes.find(n => n.type === nodeType)
      if (!nodeConfig) return

      const {template} = nodeConfig

      // Add node with position and custom data
      const nodeId = this.editor.addNode(
          nodeType,
          template.inputs,
          template.outputs,
          pos_x,
          pos_y,
          nodeType,
          template.data,
          template.html
      )

      // Trigger node created event
      this.handleNodeCreated(nodeId)
    },

    changeModule(event) {
      const moduleName = event.target.value
      this.editor.changeModule(moduleName)
    },

    addNewModule() {
      const name = prompt('Enter module name:')
      if (name && !this.modules.includes(name)) {
        this.editor.addModule(name)
        this.modules.push(name)
      }
    },

    exportFlow() {
      const data = this.editor.export()
      const blob = new Blob([JSON.stringify(data, null, 2)], {type: 'application/json'})
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'workflow.json'
      a.click()
      URL.revokeObjectURL(url)
    },

    importFlow() {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = '.json'
      input.onchange = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          try {
            const data = JSON.parse(e.target.result)
            this.editor.import(data)
          } catch (error) {
            console.error('Error importing workflow:', error)
          }
        }
        reader.readAsText(file)
      }
      input.click()
    },

    clearFlow() {
      if (confirm('Are you sure you want to clear the current workflow?')) {
        this.editor.clear()
      }
    },

    // Event handlers
    handleNodeCreated(nodeId) {
      console.log('Node created:', nodeId)
    },

    handleNodeRemoved(nodeId) {
      console.log('Node removed:', nodeId)
    },

    handleNodeSelected(nodeId) {
      console.log('Node selected:', nodeId)
    },

    handleNodeMoved(nodeId) {
      console.log('Node moved:', nodeId)
    },

    handleConnectionCreated(connection) {
      console.log('Connection created:', connection)
    },

    handleConnectionRemoved(connection) {
      console.log('Connection removed:', connection)
    },

    handleModuleChanged(moduleName) {
      console.log('Module changed:', moduleName)
      this.currentModule = moduleName
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.workflow-builder {
  height: 100%;
  padding: 20px;
}

.material-icons {
  font-size: 20px;
  vertical-align: text-bottom;
}

.drawflow-canvas {
  width: 100%;
  height: 70vh;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
  cursor: default;
}

.drawflow-canvas.dragging {
  cursor: copy;
}

.node-palette {
  max-height: calc(70vh - 60px);
  overflow-y: auto;
}

.node-palette .node-item {
  cursor: grab;
  transition: transform 0.2s;
}

.node-palette .node-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.node-palette .node-item.dragging {
  opacity: 0.5;
}

/* Drawflow customizations */
.drawflow .drawflow-node {
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  min-width: auto;
  cursor: move;
}

.drawflow .drawflow-node.selected {
  background: black;
  color: white;
}

.drawflow .connection .main-path {
  stroke: #6c757d;
  stroke-width: 2px;
}

.drawflow .connection .main-path:hover {
  stroke: #007bff;
}

.drawflow .drawflow-node.selected {
  border: 2px solid #007bff;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  width: 12px;
  height: 12px;
  background: #6c757d;
  border-radius: 50%;
}

.drawflow .drawflow-node .input:hover,
.drawflow .drawflow-node .output:hover {
  background: #007bff;
}

.btn .material-icons {
  margin-right: 4px;
}

.module-controls {
  display: flex;
  align-items: center;
}

.module-controls .form-select {
  width: auto;
  min-width: 150px;
}
</style>