<template>
  <Scheduler />
</template>

<script>


import Scheduler from "@/views/components/Sheduler.vue";

export default {
  name: "Home",
  components: {
    Scheduler
  },
};
</script>
