<template>
  <material-button
      class="action-btn h-50px"
      variant="gradient"
      :color="(isOpen ? 'danger' : 'dark')"
      size="md"
      @click="toggleMenu"
  >
    <i class="material-icons-round opacity-10 fs-6 menu-icon">{{ isOpen ? 'close' : 'menu' }}</i>
  </material-button>

  <div class="menu-items" :class="{ 'open': isOpen }">
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="primary"
        size="md"
        @click.prevent="toggleAbilitiesModal"
    >
      <i class="fas fa-star"></i>
    </material-button>
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="primary"
        size="md"
        @click.prevent="toggleConversationsModal"
    >
      <i class="fas fa-comments"></i>
    </material-button>
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="primary"
        size="md"
        @click="toggleModelsModal"
    >
      <i class="material-icons-round opacity-10 fs-5">model_training</i>
    </material-button>
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="primary"
        size="md"
        @click="toggleSettingsModal"
    >
      <i class="fas fa-tools"></i>
    </material-button>
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="primary"
        size="md"
        @click="toggleMemoriesModal"
    >
      <i class="fas fa-brain"></i>
    </material-button>
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="primary"
        size="md"
        @click="toggleConnectorsModal"
    >
      <i class="fas fa-network-wired"></i>
    </material-button>
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="primary"
        size="md"
        @click.prevent="emitChatWipe"
    >
      <i class="fas fa-plus"></i>
    </material-button>
    <material-button
        class="btn-app mt-1"
        variant="gradient"
        color="dark"
        size="md"
        @click.prevent="addFile"
    >
      <i class="fa fa-paperclip"></i>
    </material-button>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "ChatTopBar",
  data() {
    return {
      isOpen: false,
    };
  },
  components: {
    MaterialButton
  },
  computed: {
    ...mapState('experts',['selectedExpert','selectedModel']),
    expertModelsOptions() {
      console.log("expertModelsOptions",this.selectedExpert.attributes);
      // Transform the models into a suitable format for v-select
      if (this.selectedExpert && this.selectedExpert.attributes.expert_llm_models.length) {
        return this.selectedExpert.attributes.expert_llm_models.map(model => ({
          value: model.attributes.identifier, // Assuming you want to use the model's ID as the value
          title: model.attributes.name, // Using the model's name as the title
          provider: model.attributes.provider, // Using the model's provider as the title
          multiplier: model.attributes.multiplier // Using the model's provider as the title
        }));
      }
      return []; // Return an empty array if no models are available
    }
  },
  mounted() {
    if (this.expertModelsOptions.length > 0) {
      this.selectedModel = this.expertModelsOptions[this.expertModelsOptions.length - 1]; // Default to the last model if available
    }
  },
  methods: {
    ...mapMutations(['setSelectedModel', 'wipeChat']), // Assuming you have a mutation to update the selected model in your store
    updateModelSelection() {
      console.log("selectedMODEL CHATTOPBAR")

      this.setSelectedModel(this.selectedModel); // Update the selected model in Vuex store
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    saveConversation() {
      // Dispatch the saveChat action
      this.$store.dispatch('saveChat').then(() => {
        // Optional: perform any additional actions upon successful save, like showing a confirmation message.
      }).catch(error => {
        console.error("Error saving the chat:", error);
        // Optional: handle any errors, such as by showing an error message to the user.
      });
    },
    emitChatWipe(){
      this.wipeChat();
      this.$emit("start-new-chat")
    },
    addFile(){
      this.$emit('toggle-add-file-modal'); // Emitting an event to the parent

    },
    toggleConversationsModal() {
      this.$emit('toggle-conversations-modal'); // Emitting an event to the parent
    },
    toggleAbilitiesModal() {
      this.$emit('toggle-abilites-modal'); // Emitting an event to the parent
    },
    toggleModelsModal() {
      this.$emit('toggle-models-modal'); // Emitting an event to the parent
    },
    toggleSettingsModal() {
      this.$emit('toggle-settings-modal'); // Emitting an event to the parent
    },
    toggleMemoriesModal() {
      this.$emit('toggle-memories-modal'); // Emitting an event to the parent
    },
    toggleConnectorsModal() {
      this.$emit('toggle-connectors-modal'); // Emitting an event to the parent
    },
  },
  watch: {
    selectedModel() {
      this.updateModelSelection(); // Call update method whenever the selected model changes
    },
    selectedExpert() {
      // Reset the selected model when the expert changes
      if (this.expertModelsOptions.length > 0) {
        this.selectedModel = this.expertModelsOptions[0].value;
      } else {
        this.selectedModel = null;
      }
    }
  }
};
</script>


<style scoped>
.menu-items {
  width: 20%;
  position: absolute;
  bottom: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  z-index: -5;
}
.menu-icon{
  vertical-align:middle;
}
.menu-items.open {
  height: auto; /* Allows the items to expand */
  z-index: 2;
}

.h-50px{
  height: 50px;
}

.btn-app {
  width: 4rem;
  background-color: blue;
  color: white;
  margin-bottom: 10px; /* Space between buttons */
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
  transform: translateY(100rem); /* Starts off-screen */
  /* More styles... */
}
.action-btn{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.menu-items.open .btn-app {
  opacity: 2;
  z-index: 2;
  transform: translateY(0); /* Align with the toggle button */
}

/* Additional styles for transition effect */
.menu-items.open .btn-app:nth-child(1) {
  transition-delay: 0.1s;
}
.menu-items.open .btn-app:nth-child(2) {
  transition-delay: 0.2s;
}
.menu-items.open .btn-app:nth-child(3) {
  transition-delay: 0.3s;
}
.menu-items.open .btn-app:nth-child(4) {
  transition-delay: 0.4s;
}
.menu-items.open .btn-app:nth-child(5) {
  transition-delay: 0.5s;
}
.menu-items.open .btn-app:nth-child(6) {
  transition-delay: 0.6s;
}
.menu-items.open .btn-app:nth-child(7) {
  transition-delay: 0.7s;
}
.menu-items.open .btn-app:nth-child(8) {
  transition-delay: 0.8s;
}
.menu-items.open .btn-app:nth-child(9) {
  transition-delay: 0.9s;
}
</style>
