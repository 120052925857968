<template>
  <div class="container mt-0">
    <div class="card d-flex flex-row justify-content-start align-items-center mb-3 d-none">
      <img
          class="w-25 me-3 mb-0 backdrop link-icon"
          src="@/assets/img/illustrations/link-dynamic-gradient.png"
          alt="logo"
      />
      <div class="w-100">
        <h2 class="text-center">
          Appstore
          <DocumentationLink
              popoverTitle="Appstore"
              class="z-index-5 documentation-link"
              description="Learn more about Apps and how to add them"
              docLink="https://docs.b-bot.space/essentials/apps"
          />
        </h2>
      </div>
    </div>

    <!-- Search and Category Filtering Section -->
    <div class="container card p-3">
      <div class="row">
        <div class="col-md-6 mb-3">
          <material-input
              type="text"
              class="form-control"
              placeholder="Search apps..."
              v-model="searchQuery"
              id="search"
              size="xl"
          />
        </div>
        <div class="col-md-6 p-2">
          <select
              class="form-select px-2"
              v-model="selectedCategory"
          >
            <option value="all">All Categories</option>
            <option
                v-for="category in uniqueCategories"
                :key="category"
                :value="category"
            >
              {{ category }}
            </option>
          </select>
        </div>
        <p class="text-center">We are happy to provide you with {{(filteredAndSearchedApps.length)}} possible app{{(filteredAndSearchedApps.length === 1 ? '' : 's' )}}</p>
      </div>
    </div>

    <div class="container mb-5 mt-4 p-0">
      <div class="row mt-4 p-3">
        <div
            v-for="app in filteredAndSearchedApps"
            :key="app.id"
            class="col-md-3 mb-3"
        >
          <div class="card app-card">
            <span class="badge badge-pill position-absolute right end-0" :class="(app.app_state === 'experimental' ? 'badge-primary' : 'badge-success')">{{app.app_state}}</span>
            <div class="card-body">
              <img class="w-100 me-3 mb-0" :src="app.logo" alt="logo" />


              <h4 class="pt-3 pb-1 text-capitalize">{{ app.formattedName }}</h4>
              <p class="card-text">{{ app.description }}</p>
            </div>
            <div class="d-flex justify-content-around">
              <material-button
                  v-if="app.connectable && !app.connected"
                  class="btn btn-primary mb-0 w-100"
                  @click="selectApp(app)"
                  :color="color" variant="gradient"
              >
                Connect
              </material-button>
              <button
                  v-if="app.connectable && app.connected"
                  class="btn btn-primary mb-0 w-100"
                  @click="selectApp(app)"
              >
                Config
              </button>

              <a
                  v-if="!app.connectable"
                  class="btn btn-primary mb-0 w-100"
                  :href="app.href"
              >
                More Information
              </a>
            </div>
          </div>
        </div>

        <!-- No Results Message -->
        <div
            v-if="filteredAndSearchedApps.length === 0"
            class="col-12 text-center mt-4"
        >
          <p class="text-muted">No apps found matching your search or category.</p>
        </div>
      </div>
    </div>
    <!-- Modal for selected app -->
    <VueFinalModal
        v-model="showModal"
        classes="modal-container"
        class="modal-container overflow-scroll"
        content-class="modal-content"
        :hide-overlay="true"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
    >
      <!-- Only render content if an App is selected -->
      <div v-if="selectedApp" class="card my-4">

        <!-- CARD HEADER -->
        <div
            :class="'bg-gradient-' + color + ' shadow-' + color"
            class="card-header d-flex justify-content-between align-items-center p-3"
        >
          <h5 class="modal-title text-white m-0">Settings for {{ selectedApp.name }}</h5>
          <button
              type="button"
              class="btn-close btn-close-white"
              @click="closeModal"
              aria-label="Close"
          >
            <i class="material-icons-round fs-5">close</i>
          </button>
        </div>
        <p class="p-4">
          {{selectedApp.description}}
        </p>

        <!-- CARD BODY -->
        <div class="card-body">
          <!-- If auth_schemes exist, show the dynamic form and actions -->
          <div v-if="formData.auth_schemes" class="mb-3">

            <!-- ComposioDynamicForm for your auth fields -->
            <div class="row mb-4">
              <div class="col-12">
                <ComposioDynamicForm
                    :auth-data="formData"
                    @form-valid="handleFormValid"
                    @update="updateAuthData"
                    :value="authData"
                    @connect="connectApp"
                />
              </div>
            </div>

            <!-- Collapsible dropdown for actions -->
            <div v-if="appActions.actions.length">
              <details class="mb-3">
                <summary class="fw-bold fs-5 text-primary pointer">
                  Available Actions
                </summary>
                <ul class="list-group mt-3">
                  <li
                      v-for="action in appActions.actions"
                      :key="action.identifier"
                      class="list-group-item d-flex align-items-start"
                  >
                    <img
                        :src="action.logo"
                        alt="Logo"
                        class="me-3 flex-shrink-0"
                        style="width: 40px; height: 40px;"
                    />
                    <div>
                      <strong class="d-block">{{ action.name }}</strong>
                      <p class="mb-1 text-muted">{{ action.description }}</p>
                      <span class="badge bg-primary">{{ action.category }}</span>
                    </div>
                  </li>
                </ul>
              </details>
            </div>
            <!-- If no actions, show an info alert -->
            <div v-else class="alert alert-info" role="alert">
              <h4 class="alert-heading">There are no standard actions for this App!</h4>
              <p>
                If you need a custom action please contact us at
                <a href="mailto:info@b-bot.ch">info@b-bot.ch</a>.
              </p>
              <hr />
              <p class="mb-0">
                We will add support for custom actions creation in CBV5.
              </p>
            </div>
          </div>

          <!-- DynamicForm for non-OpenAI apps -->
          <DynamicForm
              v-if="selectedApp.identifier !== 'openai_assistant' && selectedApp.tool_type !== null"
              :showExpertChoose="true"
              :settings="selectedApp.settings"
              :descriptions="selectedApp.descriptions"
              :validations="selectedApp.validations"
              @update="updateFormData"
              @form-valid="handleFormValid"
          />

          <!-- OpenAIAssistant form for OpenAI apps -->
          <OpenAIAssistant
              v-if="selectedApp.identifier === 'openai_assistant'"
              :showExpertChoose="true"
              @close="closeModal"
              @update="updateFormData"
              @form-valid="handleFormValid"
          />
        </div>

        <!-- CARD FOOTER -->
        <div class="card-footer d-flex justify-content-end">
          <!-- The main Connect button is more visible here -->
          <!-- Optional Cancel button -->
          <material-button class="me-3" variant="outlined" color="dark" @click="closeModal">
            Cancel
          </material-button>
          <material-button
              @click="connectApp"
              :disabled="!isFormValid"
              :color="color"
              variant="gradient"
              class="me-3"
          >
            Connect
          </material-button>


        </div>
      </div>
    </VueFinalModal>

    <div
        class="modal fade"
        id="successModal"
        tabindex="-1"
        aria-labelledby="successModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header bg-success text-white">
            <span class="modal-title" id="successModalLabel">
              Success 🎉
            </span>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              You have successfully connected
              <strong>{{ successAppName }}</strong>!
            </p>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import DynamicForm from "@/views/components/DynamicForm.vue";
import OpenAIAssistant from "@/views/components/OpenAIAssistant.vue";
import {VueFinalModal} from "vue-final-modal";
import DocumentationLink from "./components/DocumentationLink.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import {debounce} from "lodash";
import ComposioDynamicForm from "@/views/components/ComposioDynamicForm.vue";
import {MainApiService} from "@/classes/services/MainApiService";
import MaterialButton from "@/components/MaterialButton.vue";
export const mainApiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);
import { Modal } from "bootstrap/dist/js/bootstrap";

const { VUE_APP_DATACENTER_URL, VUE_APP_MAIN_API_URL } = process.env;

export default {
  data() {
    return {
      selectedApp: null,
      showModal: false,
      formData: {},
      authData: {},
      isFormValid: false,
      selectedCategory: 'all',
      showExpertSelectionModal: false,
      searchQuery: '',
      appActions: null,
      showSuccessModal: false,
      successAppName: '',
    };
  },
  computed:{
    ...mapState(['experts', 'apps', 'user', 'color']),
    ...mapState('experts', ['selectedExpert']),
    // Computed property for unique categories

    uniqueCategories() {
      const categories = this.apps.map((app) => app.category);
      console.log(categories)
      return [...new Set(categories)]; // Get unique categories
    },

    filteredAndSearchedApps() {
      if (!this.apps || this.apps.length === 0) return [];

      const searchQuery = this.searchQuery.trim().toLowerCase();

      const formatTitle = (name) => {
        return name
            .toLowerCase()
            .replace(/_/g, " ") // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
      };

      const isAppMatch = (app) => {
        const formattedName = formatTitle(app.name).toLowerCase(); // Apply formatting

        const matchesSearch =
            !searchQuery ||
            formattedName.includes(searchQuery) || // Use formatted name
            app.description.toLowerCase().includes(searchQuery) ||
            app.category.toLowerCase().includes(searchQuery);

        const matchesCategory =
            this.selectedCategory === "all" ||
            this.selectedCategory === undefined ||
            app.category === this.selectedCategory;

        return matchesSearch && matchesCategory;
      };

      const filteredApps = this.apps.filter(isAppMatch);

      // Deduplicate by using a Map to store unique app identifiers
      const uniqueApps = new Map();
      filteredApps.forEach((app) => {
        const key = app.id || `${app.name.toLowerCase()}-${app.category.toLowerCase()}`;
        uniqueApps.set(key, { ...app, formattedName: formatTitle(app.name) }); // Store formatted name
      });

      const deduplicatedApps = Array.from(uniqueApps.values());

      // Sort the deduplicated apps alphabetically by formatted name
      const sortedApps = deduplicatedApps.sort((a, b) => {
        return a.formattedName.localeCompare(b.formattedName);
      });

      return sortedApps;
    }
  },
  methods: {
    ...mapActions(['updateUserMetadataApp']),
    ...mapMutations(['showEveryDisplay']),
    async getAppActions(identifier){
      const responseData = await mainApiService.call(
          "actions/" + identifier,
          "GET",
      );
      console.log("AVAILABLE ACTIONS", responseData);
      return responseData;
    },
    updateSearchQuery: debounce(function (newQuery) {
      this.searchQuery = newQuery;
    }, 300),
    async connectApp() {
      // Check if the selected app is of type "composio"
      if (this.selectedApp.tool_type === "composio") {
        try {
          const accessToken = this.getCookie("datacenterAccessToken");

          // Make API request to connect the tool
          const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/connect_tool`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              entity_id: this.user.user_id + "_exp_" + this.selectedExpert.id,  // Replace with actual expert selection logic
              app_name: this.selectedApp.identifier.toUpperCase(),
              auth: this.authData
            }),
          });

          // Handle unsuccessful response
          if (!response.ok) {
            throw new Error("Failed to initiate connection");
          }
          console.log("RESPONSE:", response)
          const data = await response.json();
          const redirectUrl = data.redirectUrl;
          const connection_id = data.connection_id;
          console.log("this.selectedApp",this.selectedApp)
          // Open the Expert Selection Window first

          // Wait for expert selection before redirecting (make sure expert is selected)
          const selectedExpert = await this.selectedExpert;
          if (selectedExpert) {
            console.log("FORM DATA:", this.formData)
            console.log("AUTH DATA:", this.authData)
            let key = this.selectedApp.identifier;
            const value = this.formData;
            const payload = {
              ...value,
              tool_type: 'compasio',
              auth: this.authData,
              form: this.formData,
              connection_id: connection_id,
              app_id: this.selectedApp.id,
              app_identifier: this.selectedApp.identifier,
              logo: this.selectedApp.logo,
              category: this.selectedApp.category,
            };
            // Add the selected expert to the connection request and proceed with redirection
            await this.handleSubmit(key, payload);

            window.open(redirectUrl, '_self');
          } else {
            console.error("No expert selected");
          }

        } catch (error) {
          console.error("Error connecting composio tool:", error);
        }

        // Clear selection
        this.selectedApp = null;
        return;
      }

      // Regular logic for other tool types
      let key = this.selectedApp.identifier;
      const value = this.formData;

      // Handle OpenAI Assistant specific logic
      if (this.selectedApp.identifier === 'openai_assistant') {
        key = `${this.selectedApp.identifier}_${this.formData.assistant_id}`;
      }

      const payload = {
        ...value,
        database_type: this.selectedApp.database_type,
      };

      // Handle private data submission
      if (this.formData?.private === true) {
        try {
          await this.$store.dispatch('updateUserMetadataApp', { key, value: payload });
        } catch (error) {
          console.error("Error updating metadata:", error);
        }
      } else {
        await this.handleSubmit(key, payload);
      }

      // Reset the selected app and close the modal
      this.selectedApp = null;
      this.showModal = false;
    },
    // Adjusted selectApp function
    async selectApp(app) {
      this.selectedApp = app;
      this.formData = app.settings;
      console.log(this.selectedApp);
      if (this.selectedApp.tool_type === "composio") {
        console.log("APP IS SELECTED")
        this.showExpertSelectionModal = true;
        const accessToken = this.getCookie("datacenterAccessToken");
        const identifier = this.selectedApp.identifier
        //fetch the Needed Params and show them in the form
        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/integration_details/${identifier}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }
        });

        const data = await response.json();
        console.log("data: ",data)
        if (this.selectedApp.tool_type === "composio") {
          try {
            this.appActions = await this.getAppActions(this.selectedApp.identifier);
          } catch (error) {
            console.error('Error fetching app actions:', error);
            this.appActions = null;
          }
        }
        this.formData = data
        if(this.formData.auth_schemes[0].type === "OAUTH2"){
          console.log("THIS IS OAUTH2")
          //await this.connectApp()
          //this.showModal = false;
          this.showModal = true;

        } else {

          this.showModal = true;

        }


      } else {
        this.formData = Object.assign({}, app.settings); // Ensures reactivity

        this.showModal = true; // Show modal for other tool types
      }
    },
    closeModal() {
      this.showModal = false;
    },
    handleFormValid(isValid) {
      this.isFormValid = isValid;
    },
    updateFormData(newFormData) {
      this.formData = {};
      this.formData = newFormData;
    },
    updateAuthData(newAuthData) {
      this.authData = {};
      this.authData = newAuthData;
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    async handleSubmit(key, payload) {
      const url = this.selectedExpert.id
          ? `${VUE_APP_DATACENTER_URL}/api/experts/${this.selectedExpert.id}` // PUT to update existing expert
          : `${VUE_APP_DATACENTER_URL}/api/experts`; // POST to create new expert

      const method = this.selectedExpert.id ? 'PUT' : 'POST';

      const accessToken = this.getCookie('datacenterAccessToken');

      try {
        const appIdentifier = key;
        const currentSettings = this.selectedExpert.attributes.apps || {};
        const updatedSettings = {
          ...currentSettings,
          [appIdentifier]: payload,
        };
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              apps: updatedSettings,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to ${this.selectedExpert.id ? 'update' : 'create'} expert: ${response.statusText}`);
        }

        await response.json();

        this.showModal = false;

      } catch (error) {
        console.error('Error submitting expert data:', error);
        // Handle error, e.g., showing error message to the user
      }
      this.showModal = false;
    },
  },
  async mounted() {
    this.showEveryDisplay();
    const query = new URLSearchParams(window.location.search);
    if (query.get('status') === 'success') {
      this.successAppName = query.get('appName') || '';
      // Use $nextTick to ensure the modal markup is rendered before showing it
      this.$nextTick(() => {
        const successModalEl = document.getElementById('successModal');
        const modal = new Modal(successModalEl);
        modal.show();
      });
      // Optionally, clear the query string so the modal doesn't show again on refresh
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    try {
      const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/available_tools`);
      const data = await response.json();
      const transformedTools = data.tools.map((tool, index) => ({
        id: index + 1,
        logo: tool.logo,
        connected: false, // set default
        connectable: true,
        ...tool
      }));
      console.log("Transformed Tools:", transformedTools);

      // Instead of this.apps, commit or dispatch to update Vuex state
      this.$store.commit('updateApps', transformedTools); // Or dispatch if using an action
      console.log("Updated apps in store:", this.apps);
    } catch (error) {
      console.error("Failed to fetch available tools:", error);
    }

  },
  components:{
    MaterialButton,
    ComposioDynamicForm,
    MaterialInput,
    DocumentationLink,
    VueFinalModal,
    DynamicForm,
    OpenAIAssistant,
  }
};
</script>

<style scoped>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-card {
  width: 90%;
  max-width: 600px;
  margin: auto;
  overflow: hidden; /* Prevents content from overflowing the modal */
}

.modal-fullscreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.modal-scrollable-content {
  padding: 15px;
  overflow-y: auto; /* Enables scrolling for the content area */
  overflow-x: hidden;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header-top{
  border-radius: 0 0 0.75rem 0.75rem !important;
}

.card-body, .card-footer {
  padding: 1rem;
}

.btn-close {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.mr-button{
  margin-right: 1rem;
}

.business-card {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  margin-right: 15px;
  border-radius: 50%;
}

.card-title {
  margin: 0;
  color: #333333;
  font-weight: bold;
}



.form-control::placeholder {
  color: #999999;
}

.form-select:focus {
  border-color: green;
  outline: 0;
  box-shadow: none;
}

.navigation-buttons-container {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.navigation-buttons {
  pointer-events: auto;
  width: 100%;
  background: white;
}

/* Slide-fade transition for entering and leaving */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* leave-to for <= Vue 2.1.8 */ {
  transform: translateX(-100%);
  opacity: 0;
}

/* Backward transition */
.slide-fade-reverse-enter-active, .slide-fade-reverse-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-reverse-enter, .slide-fade-reverse-leave-to /* leave-to for Vue 2.1.8 and below */ {
  transform: translateX(100%);
  opacity: 0;
}
.icon-steps{
  position: absolute;
  top: 0.7rem;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  padding: .5rem 1rem;
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.steps-indicator{
  margin-bottom: 2rem;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.dark-version .vfm--overlay {
  background: black;
}

.dark-version .vfm--content {
  background: black;
}
.app-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 300px; /* Set a maximum height for the cards */
  box-sizing: border-box;
  margin-bottom: 20px; /* Adds space between cards */
}

.card-body {
  flex-grow: 1;
  overflow: hidden; /* Prevents content overflow */
}

.card-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis if text overflows */
}

.card img {
  max-height: 100px; /* Restricts the image size */
  object-fit: contain;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: bold;
}

.btn {
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .app-card {
    max-height: none; /* Remove max height on smaller screens */
    height: auto; /* Allow cards to adjust their height based on content */
  }

  .card img {
    max-height: 80px; /* Slightly smaller images on mobile */
  }

  .card-title {
    font-size: 1rem; /* Adjust title size on mobile */
    margin-top: 1rem;

  }
}

@media (max-width: 576px) {
  .app-card {
    max-height: none;
    height: auto;
    width: 100%; /* Ensure full width for cards on very small screens */
  }

  .card img {
    max-height: 60px; /* Even smaller images on very small screens */
  }

  .card-title {
    font-size: 0.875rem; /* Further adjust title size */
    margin-top: 1rem;

  }
}
.btn {
  margin-top: 10px;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.documentation-link {
  display: inline; /* Ensure the icon appears inline */
  vertical-align: middle; /* Align vertically with the text */
  margin-left: 0px; /* Space between title and icon */
}

.link-icon{
  position: absolute;
  bottom: 0;
}
</style>
