<template>
  <div class="container-fluid">
    <div
      class="page-header min-height-300 border-radius-5px mt-4"
      style="
        background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
      "
    >
      <span :class="`mask bg-gradient-${color} shadow-${color} opacity-6`"></span>
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              :src="user.picture"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ user.name }}</h5>
            <p class="mb-0 font-weight-normal text-sm">{{ user.nickname }}</p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <material-button
                @click="logoutWithAuth0"
                class="btn-sm"
                variant="gradient"
                color="danger"
                fullWidth
            >
              Logout
            </material-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 row">
          <div class="col-12 col-md-6 col-xl-6 position-relative">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">
                  Platform Settings
                  <DocumentationLink
                      popoverTitle="Platform Settings"
                      class="z-index-5 documentation-link"
                      description="Learn more about Platform Settings"
                      docLink="https://docs.b-bot.space/essentials/profile#platform-settings"
                  />
                </h6>
              </div>
              <div class="p-3 card-body">
                <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                  Account
                </h6>
                <ul class="list-group">
                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault"
                      label-class="mb-0 text-body text-truncate w-80"
                      v-model="followsMe"
                      @change="updateUserMetadata('follows_me', $event.target.checked)"

                    >Email me when someone follows me</material-switch
                    >
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault1"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault1"
                      label-class="mb-0 text-body text-truncate w-80"
                      v-model="messageMe"
                      @change="updateUserMetadata('message_me', $event.target.checked)"

                    >Email me when someone answers on my Message</material-switch
                    >
                  </li>

                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault2"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault2"
                      label-class="mb-0 text-body text-truncate w-80"
                      v-model="mentionsMe"
                      @change="updateUserMetadata('mentions_me', $event.target.checked)"
                    >Email me when someone mentions me</material-switch
                    >
                  </li>
                </ul>
                <h6
                  class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
                >
                  Application
                </h6>
                <ul class="list-group">
                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault3"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault3"
                      label-class="mb-0 text-body text-truncate w-80"
                      v-model="features"

                      @change="updateUserMetadata('features', $event.target.checked)"

                    >New Features</material-switch
                    >
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault4"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault4"
                      label-class="mb-0 text-body text-truncate w-80"
                      v-model="updates"

                      @change="updateUserMetadata('updates', $event.target.checked)"

                    >Monthly product updates</material-switch
                    >
                  </li>
                  <li class="px-0 pb-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault5"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault5"
                      label-class="mb-0 text-body text-truncate w-80"
                      v-model="newsletter"
                      @change="updateUserMetadata('newsletter', $event.target.checked)"
                    >Subscribe to newsletter</material-switch
                    >
                  </li>
                </ul>
              </div>
            </div>
            <hr class="vertical dark" />
          </div>

          <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4 position-relative">
            <profile-info-card
                title="Profile Information"
                :description="profileInfo.profileDescription"
                :info="{
                  fullName: profileInfo.fullName,
                  mobile: profileInfo.mobile,
                  email: profileInfo.email,
                  location: profileInfo.location,
                }"
                :social="[
                  {
                    link: 'https://www.facebook.com/b-bot/',
                    icon: 'fa-facebook',
                  },
                  {
                    link: 'https://twitter.com/b-bot',
                    icon: 'fa-twitter',
                  },
                  {
                    link: 'https://www.instagram.com/b-bot/',
                    icon: 'fa-instagram',
                  },
                ]"
                :action="{
                  route: 'javascript:;',
                  tooltip: 'Edit Profile',
                }"
            />

            <hr class="vertical dark" />
          </div>
          <div class="col-12 mt-4 mb-5 position-relative">
            <div class="">
              <h6 class="mb-0">Manage API Keys</h6>
              <ApiKeys :apiKeys="apiKeys" @add-key="addApiKey" @remove-key="removeApiKey" />
            </div>
          </div>
          <div class="mt-4 col-12 col-xl-4 mt-xl-0 d-none">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Conversations</h6>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group">
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="sophie"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <h6 class="mb-0 text-sm">Sophie B.</h6>
                      <p class="mb-0 text-xs">Hi! I need more information..</p>
                    </div>
                    <a
                      class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                      href="javascript:;"
                      >Reply</a
                    >
                  </li>
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="marie"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <h6 class="mb-0 text-sm">Anne Marie</h6>
                      <p class="mb-0 text-xs">Awesome work, can you..</p>
                    </div>
                    <a
                      class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                      href="javascript:;"
                      >Reply</a
                    >
                  </li>
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="ivana"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <h6 class="mb-0 text-sm">Ivanna</h6>
                      <p class="mb-0 text-xs">About files I can..</p>
                    </div>
                    <a
                      class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                      href="javascript:;"
                      >Reply</a
                    >
                  </li>
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="peterson"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <h6 class="mb-0 text-sm">Peterson</h6>
                      <p class="mb-0 text-xs">Have a great afternoon..</p>
                    </div>
                    <a
                      class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                      href="javascript:;"
                      >Reply</a
                    >
                  </li>
                  <li
                    class="px-0 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="nick"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <h6 class="mb-0 text-sm">Nick Daniel</h6>
                      <p class="mb-0 text-xs">Hi! I need more information..</p>
                    </div>
                    <a
                      class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                      href="javascript:;"
                      >Reply</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { mapState } from 'vuex';
import {authService} from "@/auth/authService";
import MaterialButton from "@/components/MaterialButton.vue";
import ApiKeys from "@/views/components/ApiKeys.vue";
import DocumentationLink from "./components/DocumentationLink.vue";

export default {
  name: "profile-overview",
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
    };
  },
  components: {
    DocumentationLink,
    MaterialButton,
    ProfileInfoCard,
    MaterialSwitch,
    MaterialAvatar,
    ApiKeys
  },
  computed: {
    ...mapState(['user', 'author', 'color']),
    profileInfo() {
      const authorAttributes = this.author?.attributes || {};
      return {
        fullName: authorAttributes.name || 'Name not available',
        mobile: 'No number provided', // Assuming mobile number is available
        email: authorAttributes.email || 'Email not available',
        location: 'No location provided', // Assuming location is available
        profileDescription: authorAttributes.profile_information || 'Profile description not available',
      };
    },
    followsMe: {
      get() {
        return this.$store.state.user.user_metadata.follows_me || false;
      },
      set(value) {
        this.$store.dispatch('updateUserMetadata', { metadataKey: 'follows_me', newValue: value });
      }
    },
    messageMe: {
      get() {
        return this.$store.state.user.user_metadata.message_me || false;
      },
      set(value) {
        this.$store.dispatch('updateUserMetadata', { metadataKey: 'message_me', newValue: value });
      }
    },
    mentionsMe: {
      get() {
        return this.$store.state.user.user_metadata.mentions_me || false;
      },
      set(value) {
        this.$store.dispatch('updateUserMetadata', { metadataKey: 'mentions_me', newValue: value });
      }
    },
    features: {
      get() {
        return this.$store.state.user.user_metadata.features || false;
      },
      set(value) {
        this.$store.dispatch('updateUserMetadata', { metadataKey: 'features', newValue: value });
      }
    },
    updates: {
      get() {
        return this.$store.state.user.user_metadata.updates || false;
      },
      set(value) {
        this.$store.dispatch('updateUserMetadata', { metadataKey: 'updates', newValue: value });
      }
    },
    newsletter: {
      get() {
        return this.$store.state.user.user_metadata.newsletter || false;
      },
      set(value) {
        this.$store.dispatch('updateUserMetadata', { metadataKey: 'newsletter', newValue: value });
      }
    },
    apiKeys() {
      return this.$store.state.user.user_metadata.api_keys || [];
    }
  },
  methods: {
    async logoutWithAuth0() {
      try {
        await authService.logout();
        localStorage.setItem('isAuthenticated', 'false');
        this.$router.push({ name: 'SignIn' }).catch(err => {
          console.error(err);
        });
      } catch (error) {
        console.error("Authentication error:", error);
      }
    },
    updateUserMetadata(metadataKey, newValue) {
      // Dispatch the Vuex action
      this.$store.dispatch('updateUserMetadata', { metadataKey, newValue });
    },
    addApiKey(newKey) {
      const updatedKeys = [...this.apiKeys, newKey];
      this.updateUserMetadata('api_keys', updatedKeys);
    },
    removeApiKey(index) {
      const updatedKeys = this.apiKeys.filter((key, i) => i !== index);
      this.updateUserMetadata('api_keys', updatedKeys);
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.$store.state.showNavbar = true; // Hide the navbar when the component is mounted
    setNavPills();
    setTooltip();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>

<style scoped>
.documentation-link {
  display: inline; /* Ensure the icon appears inline */
  vertical-align: middle; /* Align vertically with the text */
  margin-left: 0px; /* Space between title and icon */
}
</style>