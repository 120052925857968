<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <!-- Login / Sign-In Card -->
        <div class="col-md-4 mb-4">
          <router-link to="/news" class="card-link">
            <div class="card card-hover">
              <!-- Preview Image -->
              <img
                  src="@/assets/img/hub.png"
                  class="card-img-top"
                  alt="Login Preview"
              />
              <div class="card-header card-header-primary">
                <h4 class="card-title">Login / Sign-In</h4>
              </div>
              <div class="card-body">
                <p>Access your account and stay updated with the latest news.</p>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Documentation Card -->
        <div class="col-md-4 mb-4">
          <a
              href="https://docs.b-bot.space"
              target="_blank"
              rel="noopener"
              class="card-link"
          >
            <div class="card card-hover">
              <!-- Preview Image -->
              <img
                  src="@/assets/img/docs.png"
                  class="card-img-top"
                  alt="Documentation Preview"
              />
              <div class="card-header card-header-secondary">
                <h4 class="card-title">Documentation</h4>
              </div>
              <div class="card-body">
                <p>Explore detailed guides and API references.</p>
              </div>
            </div>
          </a>
        </div>

        <!-- Homepage Card -->
        <div class="col-md-4 mb-4">
          <a
              href="https://beyond-bot.ai"
              target="_blank"
              rel="noopener"
              class="card-link"
          >
            <div class="card card-hover">
              <!-- Preview Image -->
              <img
                  src="@/assets/img/home.png"
                  class="card-img-top"
                  alt="Homepage Preview"
              />
              <div class="card-header card-header-info">
                <h4 class="card-title">Homepage</h4>
              </div>
              <div class="card-body">
                <p>Visit our main website for more information.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

  </div>
  <Socials class="mb-5" />
</template>

<script>
import { mapMutations } from "vuex";
import Socials from "@/views/components/Socials.vue";

export default {
  name: "Home",
  components: {Socials},
  methods: {
    ...mapMutations(["hideEveryDisplay"]),
  },
  mounted() {
    this.hideEveryDisplay();
  },
};
</script>

<style scoped>
.content {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.card-hover {
  transition: transform 0.2s;
  cursor: pointer;
}

.card-hover:hover {
  transform: scale(1.02);
}
</style>
