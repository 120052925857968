import {createRouter, createWebHistory} from "vue-router";
import {authService} from '@/auth/authService'; // Adjust the path as necessary
import Knowledge from "../views/Knowledge.vue";
import Chat from "../views/Chat.vue";
import Training from "../views/Training.vue";
import Dashboard from "../views/Dashboard.vue";
import Workflows from "../views/Workflows.vue";
import Tasks from "../views/Tasks.vue";
import ExpertsTable from "../views/ExpertsTable.vue";
import RTL from "../views/Rtl.vue";
import Apps from "../views/Apps.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import News from "../views/News.vue";
import Home from "../views/Home.vue";
import store from '../store'; // Assuming you have Vuex store setup
import {firebaseService} from "../../services/firebaseService";
import {jwtDecode} from 'jwt-decode';
import Loading from "@/views/Loading.vue";


const routes = [
  {
    path: "/",
    name: "/",
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: "/home",
    name: "/home",
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: "/datacenter-token",
    name: "/datacenter-token",
    meta: { requiresAuth: false },
    component: Loading,
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_DATACENTER_URL}/api/connect/auth0?redirect_uri=${process.env.VUE_APP_BASE_URL}/api-token`;
      window.location.replace(redirectUrl);
    }
  },
  {
    path: "/api-token",
    name: "/api-token",
    meta: { requiresAuth: false },
    component: Loading,
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_MAIN_API_URL}/api/connect/auth0?redirect_uri=${process.env.VUE_APP_BASE_URL}/news`;
      window.location.replace(redirectUrl); // Redirect to obtain token
    }
  },
  {
    path: "/realtime-database-token",
    name: "/realtime-database-token",
    meta: { requiresAuth: false },
    component: Loading,
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_BASE_URL}/.netlify/functions/realtime-database?redirect_uri=${process.env.VUE_APP_BASE_URL}/news`;
      window.location.replace(redirectUrl); // Redirect to obtain token
    }
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    meta: { requiresAuth: true },
    component: () => import('../views/Onboarding.vue'),
  },
  {
    path: "/news",
    name: "News",
    meta: { requiresAuth: true, requiresSubscription: true },
    component: News,
  },
  {
    path: "/chat",
    name: "Chat",
    meta: { requiresAuth: true, requiresSubscription: true },
    component: Chat,
  },
  {
    path: "/knowledge",
    name: "Knowledge",
    meta: { requiresAuth: true, requiresSubscription: true },
    component: Knowledge,
  },
  {
    path: "/training",
    name: "Training",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: Training,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: Dashboard,
  },
  {
    path: "/workflows",
    name: "Workflows",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: Workflows,
  },
  {
    path: "/tasks",
    name: "Tasks",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: Tasks,
  },
  {
    path: "/experts",
    name: "Experts",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: ExpertsTable,
  },
  {
    path: "/billing",
    name: "Billing",
    meta: { requiresAuth: true , requiresSubscription: true},
    beforeEnter() {
      window.location.replace("https://pay.b-bot.ch/p/login/3cs29k6SSeIS7bacMM")
    }
  },
  {
    path: "/rtl-page",
    name: "RTL",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: RTL,
  },
  {
    path: "/apps",
    name: "Apps",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: Apps,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true , requiresSubscription: true},
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    meta: { requiresAuth: false },
    component: SignIn,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

// Function to check if a token is expired
function isTokenExpired(token) {
  if (!token) {
    console.error("Token is empty or undefined");
    return true; // Consider it expired if no token is provided
  }

  try {
    const decoded = jwtDecode(token); // Ensure you have jwt-decode imported
    const currentTime = Date.now() / 1000; // JWT expiration is in seconds
    const expirationTime = decoded.exp; // Token expiration time from the decoded token

    // Log the expiration date and time in a readable format
    //const expirationDate = new Date(expirationTime * 1000); // Convert seconds to milliseconds

    return expirationTime < currentTime; // Returns true if expired
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // If decoding fails, consider the token expired
  }
}


async function fetchToken() {
  const token = getCookie('firebaseToken');

  if (!isTokenExpired(token)) {
    return;
  }


  try {
    await fetch(`${process.env.VUE_APP_BASE_URL}/.netlify/functions/realtime-database?redirect_uri=http://localhost:8888/news`, {
      method: 'GET',
    });

  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
}

async function checkSubscription(email) {
  try {
    const response = await fetch('/.netlify/functions/checkStripeAbo', {
      method: 'POST',
      body: JSON.stringify({ email }),
    });
    console.log(
      'Response from checkSubscription:',
      response)
    // Parse the JSON response
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error checking subscription:', error);
    throw error;
  }
}


router.beforeEach(async (to, from, next) => {
  await store.dispatch('setIsBackgroundLoading', true);
  await authService.initClient();
  await store.dispatch('setIsBackgroundLoading', false);

  // Handle non-authenticated routes first
  if (!to.meta.requiresAuth) {
    return next();
  }

  // Check authentication
  const isAuthenticated = store.state.isAuthenticated;
  if (!isAuthenticated) {
    return next('/sign-in');
  }

  // Check and refresh Firebase token if needed
  const token = getCookie('firebaseToken');
  if (isTokenExpired(token)) {
    try {
      await fetchToken();
      await firebaseService.signInWithCustomTokenServe();
    } catch {
      return next('/sign-in');
    }
  }
    // Check onboarding status
  console.log("Getting user in Check onboarding status");
  await store.dispatch('getUser', true);
  const user = store.state.user;


  console.log("Getting user before each:", user);
  const completedOnboarding = user?.user_metadata?.completed_onboarding || false;

  console.log("completedOnboarding:", completedOnboarding);
  console.log("Navigating to:", to.path);

  if (!completedOnboarding && to.path !== '/onboarding') {
    return next('/onboarding');
  }

  // Check subscription if required
  if (to.meta.requiresSubscription) {
    console.log("requiresSubscription");
    const userEmail = store.state.user?.email;
    if (!userEmail) {
      return next('/sign-in');
    }

    try {
      const subscriptionStatus = await checkSubscription(userEmail);
      console.log("subscriptionStatus", subscriptionStatus);
      if (!subscriptionStatus.hasSubscription) {
        return next('/onboarding');
      } else {
        await store.dispatch('setAbo', subscriptionStatus);

      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      return next('/error');
    }
  }

  // If all checks pass, proceed with navigation
  next();
});

router.afterEach(() => {
  store.dispatch('setIsBackgroundLoading', false);
});

export default router;
