<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Select a Model</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #default>
      <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-start card-header-top">
        <DocumentationLink
            popoverTitle="Want to Test old Models?"
            class="z-index-5"
            description="Learn more about the Model Version chooser."
            docLink="https://docs.b-bot.space/essentials/chat#the-model-selection-window"
        />
        <h5 class="modal-title text-white mx-5">Select a Model</h5>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="network-graph-container mt-4">
            <v-network-graph
                :nodes="nodes"
                :edges="edges"
                :layouts="layouts"
                :paths="paths"
                :configs="configs"
                :event-handlers="eventHandlers"
                :selected-nodes="selectedNodes"
            />
          </div>
        </div>
      </div>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>


<script>
  import { VueFinalModal } from 'vue-final-modal'
  import * as vNG from 'v-network-graph';
  import {mapState, mapActions} from 'vuex'
  import DocumentationLink from "./DocumentationLink.vue";

  export default {
    components: {
      DocumentationLink,
      VueFinalModal,
      vNetworkGraph: vNG.VNetworkGraph,
    },
    props: {
      modelValue: Boolean,
    },
    data() {
      return {
        searchQuery: '',
        selectedNodes: this.selectedModel ? [`node${this.selectedModel.id}`] : [],
        configs: vNG.defineConfigs({
          node: {
            selectable: true,
            normal: {
              type: "circle",
              radius: 20,
              strokeWidth: 0,
              strokeColor: (this.isDarkMode ? "#787878" :"#7c7c7c" ),
              strokeDasharray: "0",
              color: (this.isDarkMode ? "#838383" :"#7e7e7e" ),
            },
            hover: {
              color: (this.isDarkMode ? "#7c7c7c" :"#7c7c7c" ),
            },
            label: {
              visible: true,
              fontSize: 14,
              background: {
                visible: true,
                color: (this.isDarkMode ? "#ffffff" :"#ffffff" ),
                padding: {
                  vertical: 1,
                  horizontal: 1,
                },
                borderRadius: 4,
              },
            },
          },
          edge: {
            gap: 40,
            normal: {
              color: (this.isDarkMode ? "#878787" :"#8e8e8e" ),
            },
            selected: {
              color: (this.isDarkMode ? "#656565" :"#676767" ), // red color for selected edges
            },
            type: "curve",
          },
          path: {
            visible: false,
            curveInNode: true,
            normal: {
              width: 10,
            },
          },
        }),
        eventHandlers: {
          "node:click": ({ node }) => this.selectModel(node),
        }
      };
    },
    mounted() {
      this.updateSelectedNode(this.selectedModel);
    },
    watch: {
      selectedModel(newModel) {
        this.updateSelectedNode(newModel);
      },
    },
    computed: {
      ...mapState(['isDarkMode', 'color']),
      ...mapState('experts',['selectedExpert', 'selectedModel','models']),
      nodes() {
        const nodes = {};
        let currentX = 0;  // Horizontal position starter for new strands
        const yIncrement = 100;  // Vertical spacing between models
        const xSpacing = 200;    // Horizontal spacing for split models

        // Get model IDs from selectedExpert
        const expertModelIds = this.selectedExpert.attributes.expert_llm_models.map(model => model.id);
        // Filter models based on selectedExpert
        const expertModels = this.models.filter(model => expertModelIds.includes(model.id));
        console.log("models: ", this.models)
        console.log("expertModels: ", expertModels)
        console.log("expertModelIds: ", expertModelIds)
        // Sort models to ensure proper chaining from base to advanced
        const sortedModels = expertModels.sort((a, b) => {
          const aPrev = a.attributes.previous_models.length;
          const bPrev = b.attributes.previous_models.length;
          return aPrev - bPrev;
        });

        // Create nodes, positioning start nodes in a new line and descendants vertically aligned above their predecessors
        sortedModels.forEach((model) => {
          const nodeId = `node${model.id}`;
          const isSelected = (this.selectedModel && this.selectedModel.id === model.id);

          if (model.attributes.previous_models.length === 0) {
            // This model starts a new line
            nodes[nodeId] = {
              name: model.attributes.name,
              x: currentX,
              y: 0,
              selected: isSelected
            };

            currentX += xSpacing;  // Increment horizontal space for the next new line
          } else {
            // This model is a descendant and should be placed above its predecessor
            const prevModelId = model.attributes?.previous_models[0].id;
            const prevNode = nodes[`node${prevModelId}`];
            let xPos = prevNode.x;
            let yPos = prevNode.y + yIncrement;

            // Check if there is already a node at this Y position to avoid overlap
            Object.values(nodes).forEach(existingNode => {
              if (existingNode.y === yPos && existingNode.x === xPos) {
                xPos += xSpacing; // Move to the right if overlap detected
              }
            });

            nodes[nodeId] = {
              name: model.attributes.name,
              x: xPos,
              y: yPos,
              selected: isSelected,
              zIndex: 2,
            };
          }
        });
        return nodes;
      },
      edges() {
        const edges = {};
        console.log("edges",this.models)
        this.models.forEach(model => {
          console.log("model.attributes.previous_models: ",model.attributes.previous_models)
          if (model.attributes.previous_models.length !== 0) {
            const sourceNodeId = `node${model.attributes.previous_models[0].id}`;
            const targetNodeId = `node${model.id}`;
            const edgeId = `edge${model.id}`;
            edges[edgeId] = {
              source: sourceNodeId,
              target: targetNodeId,
              color: model.id === 'specialEdgeModelId' ? '#ff0000' : '#6699cc', // Change 'specialEdgeModelId' to the correct condition
            };
          }
        });
        return edges;
      },
      layouts() {
        const layouts = { nodes: {} };
        Object.entries(this.nodes).forEach(([nodeId, node]) => {
          layouts.nodes[nodeId] = { x: node.x, y: node.y };
        });
        return layouts;
      },
      isModalOpen: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },
    methods: {
      ...mapActions('experts',['setSelectedModel']),
      selectModel(nodeId) {
        const model = this.models.find(model => `node${model.id}` === nodeId);
        if (model) {
          this.setSelectedModel(model);
        }
        this.closeModal();
      },
      updateSelectedNode(newModel) {
        if (newModel && newModel.id) {
          const newSelectedNodeId = `node${newModel.id}`;
          this.selectedNodes = [newSelectedNodeId];
        }
      },
      closeModal() {
        this.isModalOpen = false;
      },
    },
  };
</script>


<style scoped>
.search-box {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.conversations-list {
  list-style: none;
  padding: 0;
}

.conversation-btn {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.close-btn {
  position:absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.network-graph-container {
  width: 100%;
  height: 400px; /* Adjust as needed */
}
</style>
