import { ApiService } from './ApiService';

export class MainApiService extends ApiService {
    constructor(baseURL) {
        super(baseURL);
    }

    async generateMessage(type, description, extraData = {}) {
        const requestBody = {
            messages: [
                { role: "system", message: `generate the ${type} of the expert described` },
                { role: "system", message: "Act as the B-Bot Hub Incubator, you only generate information, you never comment anything. Your purpose is to generate the content of B-Bots experts" },
                { role: "user", message: `generate the system message of: ${description}` },
            ],
            ...extraData,
        };

        return await this.request('/api/v0/function', 'POST', requestBody);
    }

    async call(functionName, method, payload, headers) {
        try {

            console.log("CALL API FUNCTION:", functionName, method, payload);
            const response = await this.request(`/api/v0/${functionName}`, method, payload, headers);

            return await response;
        } catch (error) {
            console.error('Failed to call API:', error);
            return null;
        }
    }

}
