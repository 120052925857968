<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Select a Conversation</h3>
    </template>

    <template #default>
      <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-start card-header-top">
        <DocumentationLink
            popoverTitle="Go back in Time?"
            class="z-index-5"
            description="Learn more about the conversation History."
            docLink="https://docs.b-bot.space/essentials/chat#the-chat-history"
        />
        <h5 class="modal-title text-white mx-5">Select a Conversation</h5>
      </div>

      <!-- Filter Buttons for Experts -->
      <div class="filter-buttons">
        <p class="m-2 mt-3">Filter by Experts:</p>
        <button
            v-for="expert in experts.experts"
            :key="expert.id"
            class="btn btn-sm m-2"
            :class="{ 'btn-primary': isExpertSelected(expert), 'btn-outline-primary': !isExpertSelected(expert) }"
            @click="toggleExpertFilter(expert)"
        >
          {{ expert.attributes.name }} {{(selectedExpert.id !== expert.id ? "" : "(selected)" )}}
        </button>

      </div>

      <div class="row">
        <div class="container justify-content-center">
          <div class="w-100">
            <div class="search-box mt-3 mb-3">
              <p>Search by query:</p>

              <MaterialInput id="searchChats" v-model="searchQuery" label="Search conversations..."></MaterialInput>
            </div>
            <ul class="conversations-list">
              <li v-for="(item, index) in filteredConversations" :key="index" class="conversation-item">
                <div class="card shadow-sm mt-3 mb-3 w-100">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <span>{{ item.conversation?.chatDetails.title }}</span>
                    <span>{{ item.conversation?.chatDetails.created }}</span>
                    <div class="contributors">
                      <div class="mt-2 avatar-group">
                        <a
                            v-for="({ picture: authorImage, name }, index) in getContributors(item.conversation?.messages)"
                            :key="index"
                            href="javascript:;"
                            class="avatar avatar-xs rounded-circle overflow-hidden"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="name"
                        >
                          <img :src="authorImage || defaultProfilePic" :key="item.conversation.title + name" class="img-fluid overflow-hidden" @error="imageError" />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    Last message:
                    <v-md-preview class="message-text" :text="item.conversation?.chatDetails?.lastMessage?.text"></v-md-preview>
                    <span v-if="searchQuery && item.matchingMessage">
                      matches in search:
                      <v-md-preview class="message-text" :text="item.matchingMessage?.text"></v-md-preview>
                    </span>
                  </div>

                  <button @click="selectConversation(item.conversation)" class="btn bg-gradient-primary w-100 m-0">
                    Select
                  </button>
                </div>
              </li>
            </ul>
            <button @click="closeModal" class="close-btn btn">
              <i class="material-icons-round opacity-10 fs-5">close</i>
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapState, mapMutations } from 'vuex'
import MaterialInput from "../../components/MaterialInput.vue";
import DocumentationLink from "./DocumentationLink.vue";

export default {
  components: {
    DocumentationLink,
    MaterialInput,
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
      selectedFilters: [],
      defaultProfilePic: "https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg"
    };
  },
  computed: {
    ...mapState(["userChats", "user", "color"]),
    ...mapState('experts', ['selectedExpert', "experts"]),
    filteredConversations() {
      let conversations = this.userChats;

      // Reverse the array so the newest conversations are on top
      conversations = conversations.reverse();

      // Apply filters based on selected experts
      if (this.selectedFilters.length > 0) {
        conversations = conversations.filter(conversation => {
          const contributors = this.getContributors(conversation.messages);
          return contributors.some(contributor =>
              this.selectedFilters.includes(contributor.id)
          );
        });
      }

      return conversations
          .map(conversation => {
            const titleMatch = conversation.chatDetails.title
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase());

            const matchingMessage = conversation.messages
                ? Object.values(conversation.messages).find(message =>
                    message.text.toLowerCase().includes(this.searchQuery.toLowerCase())
                )
                : null;

            return {
              conversation,
              matchingMessage,
              matches: titleMatch || !!matchingMessage,
            };
          })
          .filter(item => item.matches);
    },
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapMutations(['setSelectedConversation']),
    selectConversation(conversation) {
      this.setSelectedConversation(conversation);
      this.isModalOpen = false;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    getContributors(messages) {
      const contributors = {};
      Object.values(messages).forEach(message => {
        let senderName = "Unknown";
        let senderPicture = this.defaultProfilePic;

        if (message.sender === "user" && this.user.user_id === message.senderId) {
          senderName = "You";
          senderPicture = this.user.picture || this.defaultProfilePic;
        } else if (this.experts) {
          Object.values(this.experts).forEach(expert => {
            if (expert.id === message.senderId) {
              senderName = expert.attributes.name;
              // Check if the picture URL is valid; if not, use default
              const expertPictureUrl = expert.attributes?.profile_picture?.attributes?.formats?.small?.url;
              senderPicture = (expertPictureUrl && expertPictureUrl.startsWith('http')) ? expertPictureUrl : this.defaultProfilePic;
            }
          });
        }

        if (!contributors[message.senderId]) {
          contributors[message.senderId] = { id: message.senderId, name: senderName, picture: senderPicture }; // Add id here
        }
      });
      return Object.values(contributors);
    },
    // Toggle expert filters
    toggleExpertFilter(expert) {
      const index = this.selectedFilters.indexOf(expert.id); // Use expert.id instead of expert.attributes.name
      if (index > -1) {
        this.selectedFilters.splice(index, 1); // Remove the expert if already selected
      } else {
        this.selectedFilters.push(expert.id); // Add the expert if not selected
      }
    },
    // Check if an expert is selected
    isExpertSelected(expert) {
      return this.selectedFilters.includes(expert.id); // Use expert.id instead of expert.attributes.name
    },
    imageError(event) {
      // When an error occurs, replace the src with the fallback image
      event.target.src = this.defaultAvatarUrl;
    }
  },
};
</script>

<style scoped>
.search-box {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.conversations-list {
  list-style: none;
  padding: 0;
}

.conversation-btn {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.confirm-modal-content > * + *{
  margin: 0.5rem 0;
}
.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}

</style>
