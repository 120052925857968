<template>
  <div class="container">
    <div v-if="isLoading" class="loading-overlay">
      <vue3-lottie ref="lottie"
                   :animationData="animationData"
                   height="60vh"
                   width="100%"
      />
    </div>
    <div class="row">
      <h6 class="text-secondary mb-2">App Connections</h6>
      <!-- Displaying app connections -->
      <div class="row mb-5" v-if="appKeys.length > 0">
        <div class="col-md-3" v-for="appKey in appKeys" :key="appKey">
          <div class="card app-item shadow-sm app-card">
            <img v-if="getAppDetails[appKey].tool_type !== 'compasio' " :src="getAppDetails[appKey]?.image" :alt="getAppDetails[appKey]?.name" class="card-img-top app-icon"/>
            <img v-else :src="getAppDetails[appKey]?.logo" :alt="getAppDetails[appKey]?.logo" class="card-img-top app-icon"/>
            <div class="card-body">
              <h5 class="card-title text-center">{{ getAppDetails[appKey]?.name }}</h5>
              <div v-if="apps[appKey]?.actions">
                <div v-for="(action, actionKey) in apps[appKey].actions" :key="actionKey">
                  <button @click="performAction(appKey, actionKey)" class="btn btn-primary w-100 m-0 mb-2">
                    {{ action.name }}
                  </button>
                </div>
              </div>

            </div>
            <div class="d-flex justify-content-around">
              <button @click="editApp(appKey)" class="btn btn-secondary w-100 m-0 m-0">
                Edit
              </button>
              <button @click="deleteApp(getAppDetails[appKey], appKey)" class="btn btn-danger w-100 m-0">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Message when no app connections found -->
      <div v-else>
        <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-warning p-0">
          <div class="col-12 col-md-6">
            <div class="alert-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <img
                class="w-75 me-3 mb-0"
                src="@/assets/img/illustrations/link-dynamic-gradient.png"
                alt="logo"
            />
          </div>
          <div class="col-12 col-md-6">
            <h1 style="color: white">App Connections</h1>

            <h2 style="color: white">You have no Connections configured</h2>
            <p>
              please finish creating the Expert and/or visit the Apps section
            </p>

          </div>
          <div class="d-flex justify-content-around p-0 d-none">
            <button class="btn btn-primary w-100 m-0" @click="toNext()">
              Next Step
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit App Modal -->
    <VueFinalModal
        v-model="showEditModal"
        classes="modal-container"
        class="modal-container overflow-scroll modal-fullscreen"
        content-class="modal-content"
        :hide-overlay="true"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
    >
      <div v-if="editingApp" class="mt-4 mb-4 card p-3">
        <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-between card-header-top p-3">
          <h5 class="modal-title text-white">Settings for {{ getAppDetails[editingApp]?.name }}</h5>
          <p>

          </p>
          <button type="button" class="close-btn btn text-white" @click="closeEditModal">
            <i class="material-icons-round opacity-10 fs-5">close</i>
          </button>
        </div>

        <div v-if="expertApps[editingApp].tool_type === 'compasio'" class="mt-4 mb-4 card p-3">
          <div v-if="appActions.actions.length !== 0">

            <h3 class="mb-3">Available Actions</h3>
            <ul class="list-group">
              <li v-for="action in appActions.actions" :key="action.identifier" class="list-group-item d-flex align-items-start text-start">
                <img :src="action.logo" alt="Logo" class="me-3 flex-shrink-0 h-100" />
                <div class="flex-grow-1 overflow-auto">
                  <strong class="d-block">{{ action.name }}</strong>
                  <p class="mb-1 text-muted">{{ action.description }}</p>
                  <span class="badge bg-primary">{{ action.category }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="mb-3">
            <div class="alert alert-info mt-5" role="alert">
              <h4 class="alert-heading">There are no standard actions for this App!</h4>
              <p>
                If you need an custom action please contact us at <a href="mailto:info@b-bot.ch">info@b-bot.ch</a>.
              </p>
              <hr>
              <p class="mb-0">We will add the support of custom actions creation in CBV5</p>
            </div>
          </div>
        </div>
        <div v-else>
          <DynamicForm
              v-if="editingApp !== 'openai_assistant' && expertApps[editingApp].tool_type !== 'openai_asst' || expertApps[editingApp].tool_type !== 'compasio' "
              :showExpertChoose="false"
              :settings="getAppSettings()"
              :descriptions="getAppDescriptions()"
              :validations="getAppValidations()"
              @update="updateFormData"
              @form-valid="handleFormValid"
          />
          <OpenAIAssistant
              v-if="expertApps[editingApp].tool_type === 'openai_asst'"
              :showExpertChoose="false"
              :settings="getAppSettings()"
              @close="closeEditModal"
              @update="updateFormData"
              @form-valid="handleFormValid"
          />
        </div>


        <button @click="saveAppChanges" :disabled="!isFormValid" class="btn btn-success mt-2">Save Settings</button>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import tavilyIcon from '@/assets/icons/tavily.png';
import wolframIcon from '@/assets/icons/wolfram.png';
import documentRetrieverIcon from '@/assets/icons/document_retriever.png';
import notionIcon from '@/assets/icons/notion.png';
import upstashIcon from '@/assets/icons/upstash-redis-private.png';
import loadingChat from "@/assets/img/illustrations/loadingChat.json";
import DynamicForm from "@/views/components/DynamicForm.vue";
import OpenAIAssistant from "@/views/components/OpenAIAssistant.vue";
import {VueFinalModal} from "vue-final-modal";
import {MainApiService} from "@/classes/services/MainApiService";
export const mainApiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);


export default {
  components: {
    DynamicForm,
    OpenAIAssistant,
    VueFinalModal
  },
  data() {
    return {
      isLoading: false,
      animationData: loadingChat,
      showEditModal: false,
      editingApp: null,
      editedAppData: {},
      isFormValid: false,
      appActions: null,
      entityConnections: []
    };
  },
  props: {
    expertApps: {
      type: [String, Object], // Allow expertApps to be either a String or Object
      required: true
    }
  },
  async mounted() {

  },
  computed: {
    ...mapState(['apps', 'user', 'color']),
    ...mapState('experts',['selectedExpert']),
    parsedApps() {
      if (typeof this.expertApps === 'string') {
        try {
          const parsed = JSON.parse(this.expertApps) || {};
          return parsed;
        } catch (error) {
          console.error('Error parsing expertApps:', error);
          return {};
        }
      } else if (this.expertApps && typeof this.expertApps === 'object') {
        // Create a new object that filters out the unwanted apps
        console.log(this.expertApps, "LIKE")
        const filteredApps = {};
        for (const key in this.expertApps) {
          if (Object.prototype.hasOwnProperty.call(this.expertApps, key)) {
            const app = this.expertApps[key];
            // Make sure app is an object before checking tool_type
            if (app && typeof app === 'object' && app.tool_type !== 'knowledge' && app.tool_type !== 'memory') {
              filteredApps[key] = app;
            }
          }
        }
        return filteredApps;
      } else {
        return {};
      }
    },
    appKeys() {
      return Object.keys(this.parsedApps);
    },
    appImages() {
      return {
        tavily_search: tavilyIcon,
        wolfram_alpha: wolframIcon,
        document_retriever: documentRetrieverIcon,
        notion_connector: notionIcon,
        upstash_redis_private: upstashIcon,
      };
    },
    getAppDetails() {
      const appDetails = {};
      // Iterate over the filtered apps (parsedApps) instead of the raw expertApps
      for (const [key, value] of Object.entries(this.parsedApps)) {
        appDetails[key] = {
          // Include all the properties from the original app data
          ...value,
          // Optionally override or set default values for name and image:
          name: this.apps[key]?.name || value.name,
          image:
              this.appImages[key] ||
              value.image ||
              require('@/assets/img/illustrations/link-dynamic-gradient.png'),
        };
      }
      return appDetails;
    },


  },
  async created(){
    const response = await this.getEnitityConnections(this.user.user_id + "_exp_" + this.selectedExpert.id);
    // Assuming the API response structure is { connections: [...] }
    this.entityConnections = response.connections || [];
    console.log(this.entityConnections);
  },
  methods: {
    ...mapActions(['callApiFunction']),
    ...mapActions('experts',['fetchExperts']),
    async getAppActions(identifier){
      const responseData = await mainApiService.call(
          "actions/" + identifier,
          "GET",
      );
      console.log("AVAILABLE ACTIONS", responseData);
      return responseData;
    },
    async getEnitityConnections(entityId){
      const responseData = await mainApiService.call(
          "entity_connections/" + entityId,
          "GET",
      );
      console.log("AVAILABLE ACTIONS", responseData);
      return responseData;
    },
    async getConnectionByAppUniqueId(appUniqueId) {
      return this.entityConnections.find(
          (conn) => conn.appUniqueId === appUniqueId
      );
    },
    getAppProperty(property) {
      if (typeof property !== 'string') {
        console.error('Expected property to be a string, got:', typeof property);
        return {};
      }

      // Find the corresponding expert app details
      const expertAppData = this.expertApps[this.editingApp] || null;
      if (!expertAppData) {
        console.warn('No expert app data found for editingApp:', this.editingApp);
        return {};
      }

      expertAppData.id = this.editingApp;

      // Special handling for specific tool types
      if (expertAppData.tool_type === "openai_asst") {
        expertAppData.id = "openai_assistant";
      }

      // Log the expert app data for verification

      // Find the matching app data from `this.apps` based on identifier
      const appData = Object.values(this.apps).find(app => app.identifier === expertAppData.id) || null;

      // Log the found app data for debugging

      // If appData exists, merge expertAppData into appData's settings
      let result;
      if (appData) {
        if (property === 'settings') {
          // Merge expertAppData into appData settings
          result = { ...appData.settings, ...expertAppData };
        } else {
          // For other properties, use appData[property] and fallback to expertAppData
          result = { ...appData[property], ...expertAppData[property] };
        }
      } else {
        // If no appData, return expertAppData's property as fallback
        result = { ...expertAppData[property] };
      }

      // Log the merged or overwritten property value

      return result || {};
    },

    getAppSettings() {
      return this.getAppProperty('settings');
    },

    getAppDescriptions() {
      return this.getAppProperty('descriptions');
    },

    getAppValidations() {
      return this.getAppProperty('validations');
    },
    async editApp(appKey) {
      this.editingApp = appKey;
      this.editedAppData = { ...this.parsedApps[appKey] };
      console.log("this.editingApp", this.editingApp);
      this.appActions = await this.getAppActions(this.editingApp);

      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editingApp = null;
      this.editedAppData = {};
    },
    async saveAppChanges() {
      console.log("SAVE APP CHANGES")
      if (this.editingApp && this.isFormValid) {
        console.log("SAVE APP CHANGES editingApp", this.editingApp, this.isFormValid, this.editedAppData);

        try {
          await this.handleSubmit(this.editingApp, this.editedAppData);
          //this.$emit('app-updated', this.editingApp, this.editedAppData);
          this.closeEditModal();
        } catch (error) {
          console.error('Failed to update app:', error);
        }
      }
    },
    async handleSubmit(key, payload) {
      console.log("HANDLE SUBMIT")

      const appIdentifier = key;
      const currentSettings = this.selectedExpert.attributes.apps || {};
      const updatedSettings = {
        ...currentSettings,
        [appIdentifier]: payload,
      };

      this.$emit("update-apps", updatedSettings);


      this.showModal = false;


    },
    updateFormData(newFormData) {
      this.editedAppData = newFormData;
    },
    handleFormValid(isValid) {
      this.isFormValid = isValid;
    },
    async deleteApp(app, appKey) {
      console.log("app", app);
      const connection = await this.getConnectionByAppUniqueId(appKey)
      console.log("connection:", connection)
      const responseData = await mainApiService.call(
          "connections/" + connection.id,
          "DELETE",
      );
      console.log("AVAILABLE ACTIONS", responseData);
      this.$emit('delete-app', appKey);
    },
    toNext() {
      this.$emit('to-next')
    },
    performAction(appKey, action) {
      this.isLoading = true;

      switch(appKey) {
        case 'notion_connector':
          if (action === 'sync') {
            this.syncNotionConnector();
          }
          break;
          // Add more cases for other apps and their respective actions
        default:
          console.log(`Action ${action} for app ${appKey} is not defined.`);
      }
    },
    async syncNotionConnector() {

      const payload = {
        id: this.selectedExpert.id.toString(), // Replace with actual user ID logic
        apps: this.selectedExpert.attributes.apps,
        private: true
      };


      try {
        await this.callApiFunction({ functionName: 'connect_notion', payload });
        // Handle the response as needed
      } catch (error) {
        console.error('Failed to sync Notion Connector:', error);
      }
      this.isLoading = false;

    }
  }
};
</script>

<style scoped>


.app-item {
  margin-bottom: 20px;
}
.app-icon {
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 20px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.app-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* This makes sure the card takes up the full height */
}

.card-body {
  flex-grow: 1;
}

.card-text {
  flex-grow: 1;
}

.card img {
  max-height: 150px;
  object-fit: contain;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.btn {
  margin-top: 10px;
}

</style>
