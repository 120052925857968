<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Connectors</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #default>
      <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-start card-header-top">
        <DocumentationLink
            popoverTitle="Connectors and Tools"
            class="z-index-5"
            description="Learn more about the Connectors and Tools."
            docLink="https://docs.b-bot.space/essentials/chat#the-tools-and-connectors-window"
        />
        <h5 class="modal-title text-white mx-5">Connectors & Tools</h5>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="connector-switch card col-12 col-md-6 col-lg-4" v-for="(toolKey, index) in Object.keys(allTools)" :key="index">
            <label class="w-100 text-align-center" :for="toolKey">

              <div class="card-body">
                <material-switch
                    type="checkbox"
                    :id="toolKey"
                    v-model="toolActivation[toolKey]"
                    @change="toggleTool({ tool: toolKey, value: toolActivation[toolKey] })"
                />
                  <h5 :class="getTextColor(toolKey)" class="card-title">{{ toolDescriptions[toolKey].name }}</h5>
                  <p :class="getTextColor(toolKey)" class="card-text">{{ toolDescriptions[toolKey].description }}</p>
                  <img :class="'w-50 background-' + getTextColor(toolKey)" :src="toolDescriptions[toolKey].image" alt="Tool Image" class="tool-image" />
              </div>
            </label>

          </div>
        </div>
      </div>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import { mapState, mapMutations } from 'vuex';
import MaterialSwitch from '../../components/MaterialSwitch.vue';
import DocumentationLink from "./DocumentationLink.vue";

export default {
  components: {
    DocumentationLink,
    MaterialSwitch,
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  computed: {
    ...mapState(['toolActivation', 'apps', 'color']),
    ...mapState('experts', ['selectedExpert']),
    allTools() {
      const expertApps = this.selectedExpert?.attributes?.apps || {};
      const mergedTools = {};

      // Include all tools from toolActivation, regardless of their activation state
      Object.keys(this.toolActivation).forEach(toolKey => {
        mergedTools[toolKey] = this.toolActivation[toolKey];
      });

      // Add the expert's apps to the merged list, without duplicates
      Object.keys(expertApps).forEach(appKey => {
        if (this.toolActivation[appKey] !== undefined) {
          mergedTools[appKey] = this.toolActivation[appKey];
        } else {
          mergedTools[appKey] = false; // Default state for the expert's apps
        }
      });

      return mergedTools;
    },
    toolDescriptions() {
      const descriptions = {};
      const expertApps = this.selectedExpert?.attributes?.apps || {}; // Expert-specific apps

      // Add descriptions for expert's apps first
      Object.keys(expertApps).forEach(appKey => {
        const app = expertApps[appKey] || {};
        const appInfo = this.apps.find(app => app.identifier === appKey) || {}; // Get additional info if available

        descriptions[appKey] = {
          name: appInfo.name || app.name || appKey.replace('_', ' ').toUpperCase(),
          description: appInfo.description || app.description || `Description for ${appKey.replace('_', ' ')}`,
          image: appInfo.logo || app.logo || `/img/link-dynamic-gradient.eefa9a8d.png`, // Fallback to default image
        };
      });

      // Add standard tools from toolActivation, making sure not to duplicate
      Object.keys(this.toolActivation).forEach(toolKey => {
        if (!descriptions[toolKey]) { // Ensure no duplicates with expert apps
          const appInfo = this.apps.find(app => app.identifier === toolKey) || {}; // Look for extra info

          descriptions[toolKey] = {
            name: appInfo.name || toolKey.replace('_', ' ').toUpperCase(),
            description: appInfo.description || `Description for ${toolKey.replace('_', ' ')}`,
            image: appInfo.logo || `/img/link-dynamic-gradient.eefa9a8d.png`, // Default or fallback image
          };
        }
      });

      return descriptions;
    },
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    isAnyToolActivated() {
      return Object.values(this.toolActivation).some(value => value === true);
    },
  },
  methods: {
    ...mapMutations(['toggleToolActivation']),
    toggleTool(payload) {
      this.toggleToolActivation(payload);
    },
    closeModal() {
      if (!this.isAnyToolActivated) {
        // Show an alert or a notification
        alert("You must activate at least one tool before proceeding.");
        return; // Prevent closing the modal
      }
      this.isModalOpen = false;
    },
    getTextColor(toolKey) {
      return this.toolActivation[toolKey] ? 'text-success' : 'text-danger'; // Change text color based on activation status
    },
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
}

.text-align-center {
  text-align: center;
}

.connector-switch {
  margin: 1rem;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.confirm-modal {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.connector-switch input {
  margin-right: 0.5rem;
}

.connector-switch label {
  margin: 0;
}

.card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.card-header {
  border-radius: 0.75rem !important;
}

/* Add your custom color classes */
.text-success {
  color: #000000 !important; /* Change to your desired color for active state */
}

.text-danger {
  color: lightgrey !important; /* Change to your desired color for inactive state */
}

.background-text-danger {
  filter: grayscale(90%);
}

.form-switch {
  padding-left: 2.375rem;
  position: absolute;
  top: -3px;
  right: -17px;
}
</style>
