<template>
  <div class="card p-3 mb-5">
    <h4>Questioning Person</h4>
    <div id="expertCarousel" class="carousel slide mb-3" data-ride="carousel" :data-interval="false">
      <div class="carousel-inner p-5">
        <div
            class="carousel-item"
            v-for="(chunk, index) in expertChunks"
            :key="index"
            :class="{ active: index === activeIndex }"
        >
          <div class="row">
            <div class="col-md-4" v-for="(expert) in chunk" :key="expert.id">
              <div class="card shadow-sm expert-card" :class="{ selected: selectedAskingExpert === expert }">
                <div class="card-body text-center">
                  <img :src="expert.attributes.profile_picture?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'" class="rounded-circle mb-3" :alt="expert.attributes.name" style="width: 100px; height: 100px;" />
                  <h5 class="card-title">{{ expert.attributes.name }}</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>
                  <p class="card-text">{{ expert.attributes.function_name }}</p>
                  <p class="card-text">{{ expert.attributes.function_description }}</p>

                </div>
                <div class="d-flex justify-content-around">
                  <button
                      class="btn btn-primary w-100 mb-0"
                      @click="selectExpert(expert)"
                      :disabled="isLoading"
                  >
                    Select Expert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="carousel-control-prev" role="button" @click="prevSlide">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" role="button" @click="nextSlide">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="form-group mb-3">
      <material-textarea
          ref="messageInput"
          id="message"
          v-model="customerName"
          @input="handleInput"
          placeholder="Enter Context..."
          name="customerName"
          class="message-input flex-grow-1"
          :disabled="isLoading"
      />
    </div>
    <div class="align-items-center mb-3">
      Number of Questions: {{numberOfQuestions}}
      <input type="range" class="form-range" min="1" max="5" step="1" v-model="numberOfQuestions">

    </div>
    <div class="align-items-center mb-3">
      <material-button
          class="w-100 h-50px"
          variant="gradient"
          color="primary"
          size="md"
          @click="generateQuestions"
          :disabled="isLoading || !selectedAskingExpert || customerName.trim() === ''"
      >
        Generate Questions
      </material-button>

    </div>


    <div v-if="systemMessage" class="alert alert-info mt-3">
      <strong>System Message:</strong>
      <p>{{ systemMessage }}</p>
      <material-button
          class="action-btn w-100 h-50px"
          variant="gradient"
          color="success"
          size="md"
          @click="approveQuestions"
      >
        Approve Questions
      </material-button>
    </div>
    <div v-if="isLoading" class="loading-indicator loading-overlay">
      <vue3-lottie ref="lottie"
                   :animationData="animationData"
                   height="60vh"
                   width="100%"
      />
    </div>
    <div v-if="aiMessages.length" class="container mt-3">
      <h5>AI Questions:</h5>
      <div class="list-group">
        <div
            class="list-group-item d-flex justify-content-between align-items-center"
            v-for="(message, index) in paginatedAiMessages"
            :key="index"
        >
          <span>{{ message.content }}</span>
          <button @click="approveQuestion(message)" class="btn btn-success btn-sm m-0">Approve</button>
        </div>
      </div>
    </div>

    <div class="pagination-container d-flex justify-content-center mt-3">
      <material-pagination class="w-100">
        <material-pagination-item
            v-if="currentPage > 1"
            @click="changePage(currentPage - 1)"
            prev
        />
        <material-pagination-item
            v-for="page in totalPages"
            :key="page"
            :label="page"
            :active="page === currentPage"
            @click="changePage(page)"
        />
        <material-pagination-item
            v-if="currentPage < totalPages"
            @click="changePage(currentPage + 1)"
            next
        />
      </material-pagination>
    </div>
    <div v-if="isGeneratingAnswers" class="loading-indicator loading-overlay">
      <vue3-lottie ref="lottie"
                   :animationData="animationData"
                   height="60vh"
                   width="100%"
      />
    </div>

    <div class="container mt-3 mb-5 p-0 text-center">
      <h5>Generated Answers from {{selectedExpert.attributes.name}}:</h5>
      <material-button
          class="btn-app mt-1 w-100"
          variant="gradient"
          color="danger"
          size="md"
          @click="handleWipeGeneratedCards"
      >
        Wipe generated Answers
      </material-button>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div
          class="card mb-3 mt-3 shadow-blur p-0"
          v-for="(answer, index) in generatedAnswers"
          :key="index"
      >
        <div :class="'card-header bg-gradient-' + (answer.selected ? 'success' : 'info') + ' text-white d-flex justify-content-between align-items-center'">
          <p class="mb-0">Generated answer {{ index }}</p>
          <material-button
              class="btn-app mt-1"
              variant="gradient"
              :color="(answer.selected ? 'success' : 'info')"
              size="md"
              @click="toggleCardSelection(answer)"
          >
            {{ (answer.selected ? 'Deselect' : 'Select') }}
          </material-button>
        </div>
        <div class="card-body">
          <chat-message
              v-for="(message, msgIndex) in answer.attributes.set"
              :key="msgIndex"
              :id="msgIndex"
              :role="message.role"
              :senderId="message.senderId"
              :senderName="message.sender"
              :sender="message.sender"
              :text="message.content"
              :thoughts="message.thoughts"
              :timestamp="message.timestamp"
              :qaMarkingActivated="false"
              profilePictureUrl="https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingChat from "@/assets/img/illustrations/loadingChat.json";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialPaginationItem from "@/components/MaterialPaginationItem.vue";
import { mapState } from "vuex";
import ChatMessage from "./ChatMessage.vue";

const { VUE_APP_MAIN_API_URL } = process.env;

export default {
  name: 'SyntheticConversation',
  components: {
    ChatMessage,
    MaterialButton,
    MaterialTextarea,
    MaterialPagination,
    MaterialPaginationItem,
  },
  props:{
    generatedCards: Array
  },
  data() {
    return {
      customerName: '',
      systemMessage: '',
      selectedAskingExpert: null,
      aiMessages: [],
      numberOfQuestions: 1,
      approvedQuestions: [],
      isGeneratingAnswers: false,
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 5, // Number of questions to display per page
      activeIndex: 0, // Track the active slide index
      chunkSize: 3, // Default chunk size for desktop
      animationData: loadingChat,
    };
  },
  computed: {
    ...mapState([ 'user' ]),
    ...mapState('training', ['generatedAnswers']),
    ...mapState('experts', ['experts', 'selectedModel', 'selectedExpert']),
    expertChunks() {
      return this.experts.reduce((chunks, expert, index) => {
        if (index % this.chunkSize === 0) {
          chunks.push([]);
        }
        chunks[chunks.length - 1].push(expert);
        return chunks;
      }, []);
    },
    paginatedAiMessages() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.aiMessages.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.aiMessages.length / this.itemsPerPage);
    }
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    handleWipeGeneratedCards(){
      this.generatedAnswers.splice(0, this.generatedAnswers.length);
      this.$emit("wipe-generated-cards")
    },
    toggleCardSelection(card) {
      // Toggle the selected state of the card
      card.selected = !card.selected;
      console.log("card: ",card)
      // Emit an event to notify the parent to handle the update
      if (card.selected) {
        this.$emit('add-card', card);
      } else {
        this.$emit('remove-card', card);
      }
    },
    selectExpert(expert) {
      this.selectedAskingExpert = expert;
    },
    handleInput(event) {
      this.customerName = event.target?.value || '';
    },
    async generateQuestions() {
      this.isLoading = true; // Set loading state

      // Introduce a timeout before generating questions
      await new Promise(resolve => setTimeout(resolve, 100)); // 100 ms delay

      // Create an array of promises for all the question generation requests
      const promises = Array.from({ length: this.numberOfQuestions }, (_, i) => this.createQuestion(i));

      // Wait for all promises to complete
      const results = await Promise.all(promises);

      // Append new AI messages to the existing array instead of replacing it
      this.aiMessages = [...this.aiMessages, ...results.filter(msg => msg)];

      this.isLoading = false; // Reset loading state after generation
      this.customerName = ''; // Clear message input after sending
    },
    async createQuestion(i) {

      const payload = {
        id: this.selectedAskingExpert.id,
        s_id: this.user.id,
        user_id: this.user.user_id,
        input: `Create a question for the user input: "${this.customerName}". These questions were already used: "${this.aiMessages.map(msg => msg.text).join(', ')}, only output the question no other information, try to avoid duplicates, dont use any wrapper like "`,
        provider: this.selectedModel.provider,
        temperature: this.$store.state.chatSettings.temperature,
        conversation_history: [],
        top_p: this.$store.state.chatSettings.top_p,
        character_prompt: this.selectedAskingExpert.attributes.system_message,
        instructions: this.$store.state.chatSettings.instructions,
        model: this.selectedModel.value || this.selectedModel.attributes.identifier,
        session_id: `session-${this.user.user_id}-question`,
        apps: this.selectedAskingExpert.attributes.apps,
      };

      try {
        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/invoke`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        return {
          id: Date.now() + i,
          sender: 'user',
          role: "user",
          senderName: this.selectedAskingExpert.name,
          senderId: this.selectedAskingExpert,
          content: responseData.output,
          initiationData: responseData,
          timestamp: new Date().toLocaleString('en-US'),
        };
      } catch (error) {
        console.error("Error sending message:", error);
        this.$store.dispatch('setIsErrorInRequest', true);
        return null; // Handle the error case gracefully
      }
    },
    prevSlide() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },
    nextSlide() {
      if (this.activeIndex < this.expertChunks.length - 1) {
        this.activeIndex++;
      }
    },
    changePage(newPage) {
      this.currentPage = newPage;
    },
    checkScreenSize() {
      this.chunkSize = window.innerWidth < 768 ? 1 : 3; // Set chunk size to 1 for mobile, 3 for desktop
    },
    approveQuestions() {
      this.approvedQuestions = this.aiMessages.map(message => ({
        question: message.text,
        expert: this.selectedAskingExpert,
      }));
      alert('Questions approved! Now calling the virtual expert...');
      this.generateAnswers(); // Generate answers immediately after approval
    },
    approveQuestion(message) {
      const approvedMessage = {
        ...message,
        expert: this.selectedAskingExpert,
      };

      // Push only the approved message to the approvedQuestions array
      this.approvedQuestions.push(approvedMessage);

      // Remove the approved message from the list
      this.aiMessages = this.aiMessages.filter(msg => msg.id !== message.id);

      // Generate answer for the approved question only
      this.generateAnswers(approvedMessage);
    },
    async generateAnswers(approved) {
      this.isGeneratingAnswers = true; // Set loading state to true

      // Fetch answer for the specific approved question
      await this.fetchAnswer(approved);

      this.isGeneratingAnswers = false; // Reset loading state after generation
    },

    async fetchAnswer(approved) {
      console.log(approved)

      const payload = {
        id: approved.expert.id,
        s_id: this.user.id,
        user_id: this.user.user_id,
        input: approved.content,
        provider: this.selectedModel.provider,
        temperature: this.$store.state.chatSettings.temperature,
        conversation_history: [],
        top_p: this.$store.state.chatSettings.top_p,
        character_prompt: this.selectedExpert.attributes.system_message,
        instructions: this.$store.state.chatSettings.instructions,
        model: this.selectedModel.value || this.selectedModel.attributes.identifier,
        apps: this.selectedExpert.attributes.apps,
      };

      try {
        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/invoke`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const addedResponse = {
          id: Date.now(),
          sender: 'assistant',
          role: "assistant",
          senderName: this.selectedExpert.name,
          senderId: this.selectedExpert.name,
          content: responseData.output,
          thoughts: responseData.intermediate_steps,
          timestamp: new Date().toLocaleString('en-US'),
        }
        const answerObject = {
          id: Date.now(),
          attributes: {
            set: [approved, addedResponse]
          }
        };
        // Handle the response appropriately (add it to generatedAnswers or display it)
        this.generatedAnswers.push(answerObject);

      } catch (error) {
        console.error("Error fetching answer:", error);
        this.$store.dispatch('setIsErrorInRequest', true);
      }
    },
  },
};
</script>

<style scoped>
.expert-card{
  height: 500px;
}
.expert-card.selected {
  border: 2px solid #4caf50;
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem; /* Adjust based on your design */
}
</style>
