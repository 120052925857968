<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-5px my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="m-0 navbar-brand" href="/">
        <img
          :src="
            sidebarType === 'bg-white' ||
            (sidebarType === 'bg-transparent' && !isDarkMode)
              ? logoDark
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <span class="ms-2 font-weight-bold text-white"
              style="
                font-size: 1.5rem;
                line-height: 0;
                position: absolute;
                top: 2.6rem;
              "
          >B-Bot Hub</span
        >
      </a>
    </div>
    <div
        class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row m-2 mb-3 mt-0 cursor-pointer"
        @click="toggleModal"
    >
      <img
          class="w-10 me-3 mb-0"
          :src="selectedExpert?.attributes?.profile_picture?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'"
      />
      <p class="mb-0 title-name">
        {{ selectedExpert?.attributes?.name }}
      </p>

      <i class="material-icons-round opacity-10 fs-6 menu-icon ms-auto cursor-pointer">touch_app</i>

    </div>
    <VueFinalModal
        v-model="showExpertModal"
        classes="modal-container"
        class="modal-container overflow-scroll modal-fullscreen"
        content-class="modal-content"
        :hide-overlay="true"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
    >
      <ExpertsTable
          @toggle-modal="toggleModal"
      />
    </VueFinalModal>
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
import { mapState } from "vuex";
import ExpertsTable from "@/views/ExpertsTable.vue";
import {VueFinalModal} from "vue-final-modal";

export default {
  name: "index",
  components: {
    VueFinalModal,
    ExpertsTable,
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
      showExpertModal: false,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
    ...mapState("experts", ["selectedExpert"]),
  },
  methods: {
    toggleModal() {
      console.log("WE TOGGLE THE MODAL")
      this.showExpertModal = !this.showExpertModal;
    },
  }
};
</script>

<style scoped>
.title-name{
  max-width: 70%;
}
</style>