<script setup>
import { defineComponent } from 'vue';
import AdvancedWorkflowBuilder from "@/views/components/AdvancedWorkflow.vue";

defineComponent({
  components: {
    AdvancedWorkflowBuilder
  }
});
</script>

<template>
  <div>
    <AdvancedWorkflowBuilder />

  </div>
</template>

<style scoped>
h2 {
  text-align: center;
  margin-top: 20px;
}
</style>
