<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <h1>{{ currentSentence }}</h1>
  </div>
</template>

<script>
export default {
  name: "Loading",
  components: {
  },
  data() {
    return {
      // List of funny loading sentences
      sentences: [
        "Reticulating splines...",
        "Summoning the AI overlords...",
        "Debugging the debugger...",
        "Warming up the quantum engine...",
        "Herding cats...",
        "Aligning the stars...",
        "Consulting the cookie oracle...",
        "Brewing fresh code..."
      ],
      currentSentence: ""
    };
  },
  mounted() {
    // Set the initial sentence
    this.pickRandomSentence();
    // Optionally update the sentence every 3 seconds
    this.interval = setInterval(this.pickRandomSentence, 3000);
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval);
  },
  methods: {
    pickRandomSentence() {
      const randomIndex = Math.floor(Math.random() * this.sentences.length);
      this.currentSentence = this.sentences[randomIndex];
    }
  }
};
</script>
