<template>
  <!-- If it's an object, render a Bootstrap card -->
  <div v-if="isObject(data)" class="mb-2">
    <div class="card-body">
      <div
          v-for="(value, key) in data"
          :key="key"
          class="mb-2"
      >
        <strong>{{ key }}:</strong>
        <!-- Recursively call the same component -->
        <display-json :data="value" />
      </div>
    </div>
  </div>

  <!-- If it's an array, render a list group -->
  <ul v-else-if="Array.isArray(data)" class="list-group mb-2">
    <li
        v-for="(item, index) in data"
        :key="index"
        class="list-group-item"
    >
      <display-json :data="item" />
    </li>
  </ul>

  <!-- Otherwise, display the primitive value in a badge (you can style differently) -->
  <span v-else class="">
    {{ data }}
  </span>
</template>

<script>
export default {
  name: 'DisplayJson',
  props: {
    data: {
      type: [Object, Array, String, Number, Boolean, null],
      default: null
    }
  },
  methods: {
    isObject(val) {
      return val !== null && typeof val === 'object' && !Array.isArray(val)
    }
  }
}
</script>
